import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface VerifyOtpRequest extends Partial<AxiosRequestConfig> {
  params: {
    otp: string
  }
}

interface VerifyOtpResponseData {
  firstName: string
  isEmailVerify: boolean
  message: string
  mobileVerified: boolean
  status: number
  userId: number
}
export interface VerifyOtpResponse extends AxiosResponse {
  data: VerifyOtpResponseData
}

export function verifySigninOtpApi(
  { params, ...config }: VerifyOtpRequest,
  axiosInstance: AxiosInstance,
): Promise<VerifyOtpResponse> {
  return axiosInstance({
    params,
    method: "get",
    url: "auth/mobile/verify",
    ...config,
  })
}
