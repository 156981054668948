import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

export interface PreviewData {
  propertyId: string
  imageURL: string
  propertyName: string
}

export interface GetPropertyPreviewDataResponseData extends ResponseData {
  data: PreviewData
}

export interface GetPropertyPreviewDataResponse extends AxiosResponse {
  data: GetPropertyPreviewDataResponseData
}

export interface PropertyTypeRequest extends Partial<AxiosRequestConfig> {
  params: string | undefined
}

export function getPropertyPreviewDataApi(
  { params, ...config }: PropertyTypeRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPropertyPreviewDataResponse> {
  return axiosInstance({
    method: "get",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `newadmin/property-management/propertydata-by-propertyid?propertyId=${params}`,
    ...config,
  })
}
