import { HttpsAgent } from "agentkeepalive"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import axiosRetry from "axios-retry"

import {
  cleanCookiesStorage,
  getAuthToken,
  getReCaptchaToken,
  getTemporaryAuthToken,
  removeAuthToken,
  removeReCaptchaToken,
  removeTemporaryAuthToken,
} from "utils/local-storage"

const API_URL = process.env.REACT_APP_API_URL

const defaultConfig: AxiosRequestConfig = {
  timeout: 60000,
  httpsAgent: new HttpsAgent({ keepAlive: true }),
  baseURL: API_URL,
  headers: {
    app: "admin",
    authorization: "",
  },
}

const defaultAxiosInstance = axios.create(defaultConfig)
axiosRetry(defaultAxiosInstance)

defaultAxiosInstance.interceptors.request.use(
  (request) => {
    console.log("API call to: ", request.url)

    const authToken = getAuthToken()
    const reCaptchaToken = getReCaptchaToken()
    const temporaryToken = getTemporaryAuthToken()
    removeReCaptchaToken()

    if (temporaryToken && reCaptchaToken) {
      removeAuthToken()
      removeTemporaryAuthToken()
      return {
        ...request,
        headers: {
          ...request.headers,
          authorization: temporaryToken,
          "whimstay-auth": `whimstay ${reCaptchaToken}`,
        },
      }
    }

    if (reCaptchaToken) {
      removeAuthToken()
      removeTemporaryAuthToken()
      return {
        ...request,
        headers: {
          ...request.headers,
          "whimstay-auth": `whimstay ${reCaptchaToken}`,
        },
      }
    }

    if (authToken) {
      return {
        ...request,
        headers: {
          ...request.headers,
          authorization: authToken,
        },
      }
    }
    return request
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

defaultAxiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.message === "Network Error" || error.response?.status === 401) {
      cleanCookiesStorage()
      window?.location?.reload()
    }
    return Promise.reject(error.response ? error.response.data : error)
  },
)

export default defaultAxiosInstance
