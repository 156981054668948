import { AxiosInstance } from "axios"

import { getCallerSendOtpApi } from "api/callerVerification/getCallerSendOtp"
import { getCallerVerifyUserOtpApi } from "api/callerVerification/getCallerVerifyUserOtp"
import defaultAxiosInstance from "services/callerClient"

export class CallerVerificationClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getCallerSendOtpDetail(request: Parameters<typeof getCallerSendOtpApi>[0]) {
    return getCallerSendOtpApi(request, this.axiosInstance)
  }

  getCallerVerifyUserDetail(
    request: Parameters<typeof getCallerVerifyUserOtpApi>[0],
  ) {
    return getCallerVerifyUserOtpApi(request, this.axiosInstance)
  }
}

export const callerVerificationClient = new CallerVerificationClient(
  defaultAxiosInstance,
)
