export enum Endpoints {
  bookingDashboard = "/bookingDashboard",
  bookingDashboardDetails = "/bookingDashboard/details/:id",
  bookingInvoice = "/bookingDashboard/invoice",
  newBookingDashboard = "/newBookingDashboard",
  newBookingDashboardDetails = "/newBookingDashboard/details/:id",
  bcomBookingDashboard = "/bcomBooking",
  bcomBookingDashboardDetails = "/bcomBooking/details/:id",
  callerVerification = "/callerVerification",
  companyDashboard = "/companyDashboard",
  events = "/events",
  faq = "/faq",
  hostPayoutDashboard = "/hostPayoutDashboard",
  index = "/",
  managePromoCodes = "/promocodes/manage",
  manageTokens = "/manageTokens",
  manageUsers = "/manageUsers",
  manageRole = "/manageRole",
  onboarding = "/onboarding",
  pmsFeesManage = "/pmsFeesManage",
  propertyDashboard = "/propertyDashboard",
  redeemPromoCodes = "/promocodes/redeem",
  reports = "/reports",
  sendEmails = "/sendEmails",
  heroBanner = "/homeConfig/heroBanner",
  propertyManagement = "/homeConfig/propertyManagement",
  userDashboard = "/userDashboard",
  managePropertyDiscount = "/discount/property",
  manageCompanyDiscount = "/discount/company",
  regionalLanding = "/regional-location",
}
