import { isBrowser } from "./dom"

export interface IGoogleReCaptcha {
  execute: (siteKey: string, options?: { action: string }) => Promise<string>
  ready: (callback: () => void) => void
}

export interface IProjectWindow extends Window {
  grecaptcha: IGoogleReCaptcha
}

export const getProjectWindow = () =>
  isBrowser ? (window as Window as IProjectWindow) : undefined

export const getHeadTag = () => {
  const window = getProjectWindow()
  if (window) {
    return window.document.getElementsByTagName("head")[0]
  }
  return undefined
}

export const injectScript = (id: string, src: string, onload?: () => void) => {
  const window = getProjectWindow()
  const headTag = getHeadTag()

  if (headTag && window) {
    const scriptTag = window.document.createElement("script")
    scriptTag.id = id
    scriptTag.src = src

    if (onload) {
      scriptTag.onload = onload
    }

    if ("async" in scriptTag) {
      scriptTag.async = true
    }

    if ("defer" in scriptTag) {
      scriptTag.defer = true
    }
    headTag.appendChild(scriptTag)
    return true
  }

  return false
}

export const injectStyle = (rule: string) => {
  const window = getProjectWindow()
  const headTag = getHeadTag()

  if (headTag && window) {
    const styleEl = window.document.createElement("style")
    headTag.appendChild(styleEl)

    const styleSheet = styleEl.sheet as CSSStyleSheet
    if (styleSheet) styleSheet.insertRule(rule, styleSheet.cssRules.length)
  }
}

export const removeScript = (id: string) => {
  const window = getProjectWindow()

  if (window) {
    const scriptTag = window.document.getElementById(id)
    if (scriptTag) {
      scriptTag.remove()
      return true
    }
  }
  return false
}
