import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface UpdatePropertyTypeWiseDataResponse extends AxiosResponse {
  data: string
}

export interface UpdatePropertyTypeRequest extends Partial<AxiosRequestConfig> {
  data: string[]
}

export function updatePropertyConfigApi(
  { data, ...config }: UpdatePropertyTypeRequest,
  axiosInstance: AxiosInstance,
): Promise<UpdatePropertyTypeWiseDataResponse> {
  return axiosInstance({
    data,
    method: "put",
    url: `newadmin/update-property-configuration/by-id`,
    ...config,
  }) as Promise<UpdatePropertyTypeWiseDataResponse>
}
