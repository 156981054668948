// import { UserBasicDetails } from "services/common"

import { getCookie, setCookie } from "./cookies"

export const setAuthToken = (value: string) =>
  setCookie(process.env.REACT_APP_TOKEN_NAME || `wATAdmin`, value, 365)
export const getAuthToken = () =>
  getCookie(process.env.REACT_APP_TOKEN_NAME || `wATAdmin`) || undefined
export const removeAuthToken = () =>
  setCookie(process.env.REACT_APP_TOKEN_NAME || `wATAdmin`, "", 365)

export const setTemporaryAuthToken = (value: string) =>
  setCookie(process.env.REACT_APP_TEMP_TOKEN_NAME || `wTATAdmin`, value, 1)
export const getTemporaryAuthToken = () =>
  getCookie(process.env.REACT_APP_TEMP_TOKEN_NAME || `wTATAdmin`)
export const removeTemporaryAuthToken = () =>
  setCookie(process.env.REACT_APP_TEMP_TOKEN_NAME || `wTATAdmin`, "", 1)

export const setReCaptchaToken = (value: string) =>
  setCookie(`rCTAdmin`, value, 1)
export const getReCaptchaToken = () => getCookie(`rCTAdmin`)
export const removeReCaptchaToken = () => setCookie(`rCTAdmin`, "", 1)

export const setUserCookie = (value: number) =>
  setCookie(`ubDAdmin`, `${value}`, 365)
export const getUserCookie = () => getCookie(`ubDAdmin`)
export const removeUserCookie = () => setCookie(`ubDAdmin`, "", 365)

export const cleanCookiesStorage = () => {
  removeUserCookie()
  removeAuthToken()
  removeReCaptchaToken()
  removeTemporaryAuthToken()
}
