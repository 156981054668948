import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData } from "services/common"

export interface BcomBookingType {
  travelerName: string
  travelerEmail: string
  bookingNumber: string
  checkInDate: string
  bookingDate: string
  notes: string
  status: string
}

interface GetPaginatedBcomBookingsResponseDataData extends Pageable {
  content: BcomBookingType[]
}

interface GetPaginatedBcomBookingsResponseData extends ResponseData {
  data: GetPaginatedBcomBookingsResponseDataData
}

interface GetPaginatedBcomBookingsResponse extends AxiosResponse {
  data: GetPaginatedBcomBookingsResponseData
}
export interface GetPaginatedBcomBookingsRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    noOfRecords: number
    pageNo: number
    status: string
  }
}

export function getPaginatedBcomBookingsApi(
  { params, ...config }: GetPaginatedBcomBookingsRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedBcomBookingsResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/bcom-booking`,
    ...config,
  })
}
