import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData, SortDirectionType } from "services/common"

export interface PropertyDetailType {
  addressFull: string
  imageLink: string
  pmcId: string
  pmsUnitId: string
  propertyId: string
  propertyName: string
  propertyStatus: string
  propertyTypeDesc: string
  propertySubTypeDesc: string
  status: boolean
  updatedDt: string
  addedInGoogle?: boolean
}

interface GetPaginatedPropertyDetailResponseDataData extends Pageable {
  content: PropertyDetailType[]
}

interface GetPaginatedPropertyDetailResponseData extends ResponseData {
  data: GetPaginatedPropertyDetailResponseDataData
}

interface GetPaginatedPropertyDetailResponse extends AxiosResponse {
  data: GetPaginatedPropertyDetailResponseData
}

export interface GetPaginatedPropertyDetailRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    noOfRecords: number
    pageNo: number
    pmcId: string | number
    sortBy: string
    sortDirection: SortDirectionType
  }
}

export function getPaginatedPropertyDetailApi(
  { params, ...config }: GetPaginatedPropertyDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedPropertyDetailResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/properties/pmcId`,
    ...config,
  })
}
