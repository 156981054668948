import { useCallback, useState } from "react"

import { useToast } from "@chakra-ui/react"

import CustomModal from "components/Modals/CustomModal"
import CustomToast from "components/Toast/CustomToast"
import { useAppDispatch, useAppSelector } from "hooks/store/hooks"
import { useReCaptcha } from "hooks/useReCaptcha"
import {
  markAuthStore,
  selectIsLoginModalOpen,
  sendOtpThunk,
  verifyOtpThunk,
} from "stores/auth/authSlice"
import { cleanCookiesStorage, setReCaptchaToken } from "utils/local-storage"

import LoginForm from "./LoginForm"
import OtpForm from "./OtpForm"

const LoginModal = () => {
  const isOpen = useAppSelector(selectIsLoginModalOpen)
  const toast = useToast()
  const [isLoginStep, setIsLoginStep] = useState(true)

  const { getToken } = useReCaptcha()

  const dispatch = useAppDispatch()
  const onClose = useCallback(() => {
    dispatch(markAuthStore({ isLoginModalOpen: false }))
    setIsLoginStep(true)
  }, [dispatch])

  const onPhoneNumberSubmit = useCallback(
    async ({ code, number }: { code: string; number: string }) => {
      const token = await getToken("login")
      if (token) {
        setReCaptchaToken(token)
        const res = await dispatch(sendOtpThunk({ params: { code, number } }))
        if (res.meta.requestStatus === "rejected") {
          if (res.payload) {
            toast({
              render: () => <CustomToast message={res.payload as string} />,
            })
          }
          onClose()
        } else {
          if (res) {
            setIsLoginStep(false)
          }
        }
      } else {
        toast({
          render: () => (
            <CustomToast message={"Please check your internet connection."} />
          ),
        })
      }
    },
    [getToken, dispatch, onClose, toast],
  )

  const onRetry = useCallback(() => {
    setIsLoginStep(true)
    cleanCookiesStorage()
  }, [])

  const onSubmit = useCallback(
    async (otp: string) => {
      const token = await getToken("login")
      if (token) {
        setReCaptchaToken(token)
        const res = await dispatch(verifyOtpThunk({ params: { otp } }))
        if (res.meta.requestStatus === "rejected") {
          if (res.payload) {
            toast({
              render: () => <CustomToast message={res.payload as string} />,
            })
          }
          onClose()
        } else {
          if (res) {
            setIsLoginStep(false)
          }
        }
      } else {
        toast({
          render: () => (
            <CustomToast message={"Please check your internet connection."} />
          ),
        })
      }
    },
    [dispatch, getToken, onClose, toast],
  )

  return (
    <CustomModal title='' isOpen={isOpen} onClose={onClose} size={"xl"}>
      {isLoginStep ? (
        <LoginForm onSubmit={(payload) => void onPhoneNumberSubmit(payload)} />
      ) : (
        <OtpForm
          onRetry={onRetry}
          onSubmit={(otp) => void onSubmit(otp)}
          buttonTitle={"Sign In"}
        />
      )}
    </CustomModal>
  )
}

export default LoginModal
