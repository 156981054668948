import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { InvoiceType } from "api/bookingDashboard/getAllInvoiceApi"

interface InvoiceBookingResponse extends AxiosResponse {
  data: InvoiceType
  message: string
}

export interface InvoiceBookingRequest extends Partial<AxiosRequestConfig> {
  data: {
    invoiceId: string
    invoiceType: string
    bookingNumber: string
    invoiceItem: string
    dateOfPaymentRequest: string
    memoDescription: string
    amount: number
    dueDate: string
    stripeInvoiceStatus: string
    nighlyRate: number
    fees: number
    texes: number
    discountPercentage: number
    travellerName: string | null
    email: string | null
    isPriceBreakDown: boolean
  }
}

export function createInvoiceBookingApi(
  { data, ...config }: InvoiceBookingRequest,
  axiosInstance: AxiosInstance,
): Promise<InvoiceBookingResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `invoice`,
    ...config,
  }) as Promise<InvoiceBookingResponse>
}
