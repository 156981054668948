import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus, ResponseData } from "services/common"

interface AddChangeOrderListApiResponseData extends ResponseData {
  data: string
  apiStatus: ApiStatus
  errorMessage: string
}

interface AddChangeOrderListApiResponse extends AxiosResponse {
  data: AddChangeOrderListApiResponseData
}

export interface AddChangeOrderListApiRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    type: string
    FilterIds: string[]
  }
}

export function changeOrderListApi(
  { params, ...config }: AddChangeOrderListApiRequest,
  axiosInstance: AxiosInstance,
): Promise<AddChangeOrderListApiResponse> {
  return axiosInstance({
    data: params.FilterIds,
    method: "put",
    url: `newadmin/hero-banner/order?type=${params.type}`,
    ...config,
  })
}
