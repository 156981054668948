import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { InvoiceType } from "api/bookingDashboard/getAllInvoiceApi"

interface GetInvoiceBookingNoApiResponseData extends AxiosResponse {
  data: InvoiceType[]
}

interface GetInvoiceBookingNoApiResponse extends AxiosResponse {
  data: GetInvoiceBookingNoApiResponseData
}

export interface GetInvoiceBookingNoApiRequest
  extends Partial<AxiosRequestConfig> {
  bookingNo: string
}

export function getInvoiceBookingNoApi(
  { bookingNo, ...config }: GetInvoiceBookingNoApiRequest,
  axiosInstance: AxiosInstance,
): Promise<GetInvoiceBookingNoApiResponse> {
  return axiosInstance({
    method: "get",
    url: `invoice/getAllInvoice/${bookingNo}`,
    ...config,
  })
}
