import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface InvoiceType {
  message: string
  invoiceItem: string
  priceBreakDown: boolean
  invoiceType: string
  memoDescription: string
  travellerName: string | null
  email: string | null
  apiStatus: string
  length: number
  id: number
  invoiceId: string
  bookingNo: string
  customer: string
  nighlyRate: number
  fees: number
  texes: number
  discountPercentage: number
  totalAmount: number
  currency: string
  isActive: boolean
  isPaid: boolean
  invoiceSentDate: number
  dueDate: number
  invoiceNumber: string | null
  invoiceUrl: string | null
  stripeInvoiceStatus: string | null
  createdBy: number | null
  createdDt: number | null
  bookingNumber: string
  dateOfPaymentRequest: string
  isPriceBreakDown: boolean
  amount: number
  status: string
}

interface InvoiceResponsesDataData extends AxiosResponse {
  totalElements: number
  content: InvoiceType[]
}
interface InvoiceResponsesData extends AxiosResponse {
  data: InvoiceResponsesDataData
}

interface InvoiceResponses extends AxiosResponse {
  data: InvoiceResponsesData
}

export interface InvoiceRequests extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    noOfRecords: number
    pageNo: number
    status: string
  }
}

export function getAllInvoiceApi(
  { params, ...config }: InvoiceRequests,
  axiosInstance: AxiosInstance,
): Promise<InvoiceResponses> {
  return axiosInstance({
    method: "post",
    params,
    url: `invoice/getAllInvoice`,
    ...config,
  })
}
