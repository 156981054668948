import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

interface AddBcomNotesResponse extends AxiosResponse {
  data: string
}

export interface AddBcomNotesRequest extends Partial<AxiosRequestConfig> {
  data: {
    bookingNumber: string
    notes: string
    markAsDone: boolean
  }
}

export function addBcomNotesApi(
  { data, ...config }: AddBcomNotesRequest,
  axiosInstance: AxiosInstance,
): Promise<AddBcomNotesResponse> {
  return axiosInstance({
    method: "post",
    data,
    url: `/newadmin/bcom-notes`,
    ...config,
  })
}
