import { HttpsAgent } from "agentkeepalive"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import axiosRetry from "axios-retry"

import {
  cleanLocalsStorage,
  getAuthTokenLocal,
  getReCaptchaTokenLocal,
  getTemporaryAuthTokenLocal,
  removeAuthTokenLocal,
  removeReCaptchaTokenLocal,
  removeTemporaryAuthTokenLocal,
} from "utils/caller-local-storage"

const API_URL = process.env.REACT_APP_API_URL

const defaultConfig: AxiosRequestConfig = {
  timeout: 60000,
  httpsAgent: new HttpsAgent({ keepAlive: true }),
  baseURL: API_URL,
  headers: {
    app: "admin",
    authorization: "",
  },
}

const defaultAxiosInstance = axios.create(defaultConfig)
axiosRetry(defaultAxiosInstance)

defaultAxiosInstance.interceptors.request.use(
  (request) => {
    const authToken = getAuthTokenLocal()
    const reCaptchaToken = getReCaptchaTokenLocal()
    const temporaryToken = getTemporaryAuthTokenLocal()
    removeReCaptchaTokenLocal()

    if (temporaryToken && reCaptchaToken) {
      removeAuthTokenLocal()
      removeTemporaryAuthTokenLocal()
      return {
        ...request,
        headers: {
          ...request.headers,
          authorization: temporaryToken,
          "whimstay-auth": `whimstay ${reCaptchaToken}`,
        },
      }
    }

    if (reCaptchaToken) {
      removeAuthTokenLocal()
      removeTemporaryAuthTokenLocal()
      return {
        ...request,
        headers: {
          ...request.headers,
          "whimstay-auth": `whimstay ${reCaptchaToken}`,
        },
      }
    }

    if (authToken) {
      return {
        ...request,
        headers: {
          ...request.headers,
          authorization: authToken,
        },
      }
    }
    return request
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

defaultAxiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.message === "Network Error" || error.response?.status === 401) {
      cleanLocalsStorage()
      window?.location?.reload()
    }
    return Promise.reject(error.response ? error.response.data : error)
  },
)

export default defaultAxiosInstance
