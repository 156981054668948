import { PropsWithChildren } from "react"

import { Navigate } from "react-router-dom"

import { useAppSelector } from "hooks/store/hooks"
import { selectIsUserLoggedIn } from "stores/auth/authSlice"

const ProtectedComponent = ({ children }: PropsWithChildren) => {
  const isLoggedIn = useAppSelector(selectIsUserLoggedIn)

  return isLoggedIn ? <>{children} </> : <Navigate to='/' />
}
export default ProtectedComponent
