import { Icon, IconProps } from "@chakra-ui/react"

const LinkedinIcon = (props: IconProps) => {
  return (
    <Icon
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g
        id='svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy42OTEiIGhlaWdodD0iMTIuOTMiIHZpZXdCb3g9IjAgMCAxMy42OTEgMTIuOTMiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCA0MjciIGQ9Ik0yLjg3MyAxLjQzN0ExLjQzNyAxLjQ 1'
        clipPath='url(#clip0_328_5068)'
      >
        <path
          id='Path 427'
          d='M3.77756 1.88848C3.77782 2.26226 3.66721 2.62772 3.45971 2.93862C3.25222 3.24952 2.95717 3.49188 2.61189 3.63504C2.26662 3.7782 1.88664 3.81572 1.52004 3.74286C1.15343 3.67001 0.816677 3.49004 0.552379 3.22574C0.28808 2.96143 0.108118 2.62467 0.035262 2.25806C-0.037594 1.89145 -6.93309e-05 1.51146 0.143088 1.16618C0.286245 0.820903 0.528602 0.525846 0.839492 0.318347C1.15038 0.110847 1.51583 0.000228717 1.88961 0.000488739C2.39022 0.000836996 2.87023 0.199862 3.22421 0.553854C3.5782 0.907845 3.77722 1.38786 3.77756 1.88848ZM3.8341 5.17538H0.0568721V16.999H3.8341V5.17538ZM9.80298 5.17538H6.04416V16.999H9.76486V10.7947C9.76486 7.33815 14.2691 7.01735 14.2691 10.7947V16.999H17.999V9.50488C17.999 3.67787 11.332 3.8948 9.76354 6.75703L9.80298 5.17538Z'
          fill='#606161'
        />
      </g>
      <defs>
        <clipPath id='clip0_328_5068'>
          <rect width='18' height='16.9998' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default LinkedinIcon
