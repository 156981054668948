import { AxiosInstance, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

export interface PropertyType {
  typeId: number
  typeCode: string
  typeDescription: string
  keys: {
    bedRooms: number
    guest: number
  }
}

export interface GetPropertyTypeResponseData extends ResponseData {
  data: PropertyType[]
}

export interface GetPropertyTypeResponse extends AxiosResponse {
  data: GetPropertyTypeResponseData
}

export function getPropertyTypeApi(
  axiosInstance: AxiosInstance,
): Promise<GetPropertyTypeResponse> {
  return axiosInstance({
    method: "get",
    url: `home/property-config-type`,
  })
}
