import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface Invoice {
  id: number
  invoiceId: string
  bookingNo: string
  customer: string
  totalAmount: number
  currency: string
  isActive: boolean
  isPaid: boolean
  invoiceSentDate: number | null
  dueDate: number
  invoiceNumber: string
  invoiceUrl: string | null
  email: string
  invoiceType: string | null
  memoDescription: string
  stripeInvoiceStatus: string
  createdBy: number
  createdDt: number
}

interface InvoiceBookingResponseData extends AxiosResponse {
  data: Invoice
  message: string
}
interface InvoiceBookingResponse extends AxiosResponse {
  data: InvoiceBookingResponseData
}

export interface InvoiceBookingRequest extends Partial<AxiosRequestConfig> {
  data: {
    email: string | null
    bookingNumber: string
    invoiceItem: string
    dateOfPaymentRequest: string
    memoDescription: string
    amount: number
    dueDate: string
  }
}

export function newInvoiceBookingApi(
  { data, ...config }: InvoiceBookingRequest,
  axiosInstance: AxiosInstance,
): Promise<InvoiceBookingResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `invoice`,
    ...config,
  }) as Promise<InvoiceBookingResponse>
}
