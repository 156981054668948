import { AxiosInstance, AxiosResponse } from "axios"

export interface PMCType {
  id: string
  name: string
}
export interface PMCTypeResponse {
  companyName: string
  pmcId: string
}

export interface GetPmcIdListResponse extends AxiosResponse {
  data: PMCTypeResponse[]
}

export interface GetPmsListResponseData extends AxiosResponse {
  apiStatus: string
  data: string[]
  time: string
}

export interface GetPmsListResponse extends AxiosResponse {
  data: GetPmsListResponseData
}

export function getPMCListApi(
  axiosInstance: AxiosInstance,
): Promise<GetPmcIdListResponse> {
  return axiosInstance({
    method: "get",
    url: `admin/pmcId`,
  })
}

export function getPMSListApi(
  axiosInstance: AxiosInstance,
): Promise<GetPmsListResponse> {
  return axiosInstance({
    method: "get",
    url: `newadmin/pms`,
  })
}
