import { AxiosInstance } from "axios"

import { getPMCListApi, getPMSListApi } from "api/auth/getPmcIdList"
import { getPaginatedPropertyDetailApi } from "api/propertyDashboard/getPaginatedPropertyDetailApi"
import { getPaginatedAdminUserDetailApi } from "api/userDashboard/getPaginatedAdminUserDetailApi"
import { getPaginatedEventLogApi } from "api/userDashboard/getPaginatedEventLogApi"
import { getPaginatedUserDetailApi } from "api/userDashboard/getPaginatedUserDetailApi"
import defaultAxiosInstance from "services/client"

export class CommonClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getPMCList() {
    return getPMCListApi(this.axiosInstance)
  }

  getPMSList() {
    return getPMSListApi(this.axiosInstance)
  }

  getPaginatedUserDetail(
    request: Parameters<typeof getPaginatedUserDetailApi>[0],
  ) {
    return getPaginatedUserDetailApi(request, this.axiosInstance)
  }

  getPaginatedAdminUserDetail(
    request: Parameters<typeof getPaginatedAdminUserDetailApi>[0],
  ) {
    return getPaginatedAdminUserDetailApi(request, this.axiosInstance)
  }

  getPaginatedEventLog(request: Parameters<typeof getPaginatedEventLogApi>[0]) {
    return getPaginatedEventLogApi(request, this.axiosInstance)
  }

  getPaginatedPropertyDetail(
    request: Parameters<typeof getPaginatedPropertyDetailApi>[0],
  ) {
    return getPaginatedPropertyDetailApi(request, this.axiosInstance)
  }
}

export const apiCommonClient = new CommonClient(defaultAxiosInstance)
