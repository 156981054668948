import { useCallback, useEffect } from "react"

import { HamburgerIcon } from "@chakra-ui/icons"
import { Box, Button, IconButton } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "hooks/store/hooks"
import { logout } from "stores/auth/authSlice"
import {
  toggleSidebar,
  getPMCListThunk,
  selectPMCList,
  getRoleDetailsThunk,
} from "stores/ui/uiSlice"
import { getAuthToken } from "utils/local-storage"

import SignOutIcon from "./Icons/SignOutIcon"
import Title from "./Title"

const Header = () => {
  const dispatch = useAppDispatch()
  const handleToggle = () => {
    dispatch(toggleSidebar())
  }

  const isIndexPage = useLocation().pathname === "/"
  const pmcIdList = useAppSelector(selectPMCList)

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  useEffect(() => {
    const token = getAuthToken()
    if ((!pmcIdList || pmcIdList?.length === 0) && token) {
      void dispatch(getPMCListThunk())
      void dispatch(getRoleDetailsThunk())
    }
  }, [dispatch, pmcIdList])

  return (
    <Box
      as='nav'
      top='0'
      position={"sticky"}
      w='full'
      py={4}
      pl={4}
      pr={{ base: 5, lg: "60px" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      bg='offWhite'
      zIndex={999}
      borderBottom='1px solid #EEE'
    >
      <Box display={"flex"} alignItems={"center"} gap='10px'>
        {isIndexPage || (
          <IconButton
            variant={"icon"}
            aria-label='sidebar button'
            icon={<HamburgerIcon w='6' h='6' fill='#000' />}
            onClick={handleToggle}
          />
        )}
        <Title />
      </Box>
      <Box>
        <Button
          p='8px 24px'
          h='48px'
          fontWeight='600'
          fontSize='15px'
          variant='outline'
          boxShadow='0px 4px 16px 0px rgba(34, 34, 34, 0.08)'
          onClick={handleLogout}
        >
          SIGN OUT
        </Button>
      </Box>
    </Box>
  )
}

export default Header
