import { PropsWithChildren, useEffect } from "react"

import { Box } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "hooks/store/hooks"
import {
  getUserDetailsThunk,
  logout,
  selectUserId,
} from "stores/auth/authSlice"
import { selectIsSidebarOpen } from "stores/ui/uiSlice"
import { getAuthToken } from "utils/local-storage"

import Footer from "./Footer"
import Header from "./Header"
import IndexHeader from "./IndexHeader"
import SideDrawer from "./SideDrawer"

const Layout = ({ children }: PropsWithChildren) => {
  const isIndexPage = useLocation().pathname === "/"

  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const isOpen = useAppSelector(selectIsSidebarOpen)

  useEffect(() => {
    const token = getAuthToken()
    if (!userId && token) {
      void dispatch(getUserDetailsThunk())
    }
    if (!token) {
      dispatch(logout())
    }
  }, [dispatch, userId])
  return (
    <Box display={"flex"} minH='100vh'>
      {isIndexPage || <SideDrawer />}
      <Box
        w={{
          base: "100%",
          md: isIndexPage
            ? "100%"
            : isOpen
            ? "calc(100% - 300px)"
            : "calc(100% - 90px)",
        }}
        bg='offWhite'
      >
        {isIndexPage ? <IndexHeader /> : <Header />}
        <Box w='full' p={{ base: 4 }}>
          {children}
        </Box>
        <Footer isIndexPage={isIndexPage} />
      </Box>
    </Box>
  )
}

export default Layout
