import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

export interface PropertyTypeWiseData {
  id: string
  imageURL: string
  propertyName: string
  propId: number
  guests: number
  bedrooms: number
  updatedDt: number
  previewOnHomePage: boolean
  status: string
  city: string
}

export interface GetPropertyTypeWiseDataResponseData extends ResponseData {
  data: PropertyTypeWiseData[]
}

export interface GetPropertyTypeWiseDataResponse extends AxiosResponse {
  data: GetPropertyTypeWiseDataResponseData
}

export interface PropertyTypeRequest extends Partial<AxiosRequestConfig> {
  params: number
}

export function getPropertyTypeWiseDataApi(
  { params, ...config }: PropertyTypeRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPropertyTypeWiseDataResponse> {
  return axiosInstance({
    method: "get",
    url: `newadmin/property-management/all?typeId=${params}`,
    ...config,
  })
}
