import { useCallback } from "react"

import { Box, Button } from "@chakra-ui/react"

import { useAppDispatch } from "hooks/store/hooks"
import { markAuthStore } from "stores/auth/authSlice"

import Logo from "./Icons/Logo"
import SignOutIcon from "./Icons/SignOutIcon"
import LoginModal from "./Login/LoginModal"

const IndexHeader = () => {
  const dispatch = useAppDispatch()

  const openLoginModal = useCallback(() => {
    dispatch(markAuthStore({ isLoginModalOpen: true }))
  }, [dispatch])
  return (
    <Box
      as='nav'
      top='0'
      position={"sticky"}
      w='full'
      p='6'
      shadow={"md"}
      bg='offWhite'
      zIndex={10}
    >
      <Box
        maxW={{ base: "100%", sm: "90%", md: "85%", lg: "80%" }}
        mx='auto'
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} gap={4}>
          <Logo h={{ base: "50px", md: "60px" }} />
        </Box>
        <Box>
          <Button
            variant='ghost'
            leftIcon={<SignOutIcon />}
            onClick={openLoginModal}
            _hover={{ color: "#000" }}
          >
            SIGN IN
          </Button>
        </Box>
      </Box>
      <LoginModal />
    </Box>
  )
}

export default IndexHeader
