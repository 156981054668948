import { useEffect } from "react"

import { Text } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

import { Endpoints } from "types"

const mappingToTitle = {
  [Endpoints.manageUsers]: "Manage Admin Users",
  [Endpoints.propertyDashboard]: "Property Dashboard",
  [Endpoints.userDashboard]: "User Dashboard",
  [Endpoints.bookingDashboard]: "Old Booking Dashboard",
  [Endpoints.newBookingDashboard]: "Booking Dashboard",
  [Endpoints.companyDashboard]: "Company Dashboard",
  [Endpoints.manageCompanyDiscount]: "Company Discount",
  [Endpoints.managePropertyDiscount]: "Property Discount",
  [Endpoints.hostPayoutDashboard]: "Host Payout Dashboard",
  [Endpoints.faq]: "FAQ",
  [Endpoints.reports]: "Reports",
  [Endpoints.manageRole]: "Manage Role",
  [Endpoints.manageTokens]: "Manage Tokens",
  [Endpoints.onboarding]: "Onboarding",
  [Endpoints.events]: "Events",
  [Endpoints.pmsFeesManage]: "PMS Fees Manage",
  [Endpoints.managePromoCodes]: "Manage Promo Codes",
  [Endpoints.redeemPromoCodes]: "Redeemed Promo Codes",
  [Endpoints.sendEmails]: "Send Emails",
  [Endpoints.callerVerification]: "Caller Verification",
  [Endpoints.heroBanner]: "Hero Banner",
  [Endpoints.propertyManagement]: "Featured Property",
  [Endpoints.regionalLanding]: "Regional Landing",
  [Endpoints.bcomBookingDashboard]: "Bcom Booking Dashboard",
}

export const getTitleForUrl = (url: string): string | undefined => {
  const urlTitleMap: { [key: string]: string } = {
    "bookingDashboard/details": "Old Booking Dashboard",
    "bookingDashboard/invoice": "Old Booking Dashboard",
    "newBookingDashboard/details": "Booking Dashboard",
    "newBookingDashboard/invoice": "Booking Dashboard",
    "bcomBooking/details": "Bcom Booking Dashboard",
  }

  return urlTitleMap[url]
}

const Title = () => {
  const location = useLocation()
  const getUrl = location.pathname.split("/")
  const newUrl = getUrl[1] + "/" + getUrl[2]

  useEffect(() => {
    document.title =
      mappingToTitle[location.pathname as keyof typeof mappingToTitle] ||
      "Whimstay Admin Portal"
  }, [location.pathname])

  const title = mappingToTitle[location.pathname as keyof typeof mappingToTitle]
  const titles = getTitleForUrl(newUrl) || title
  return (
    <Text
      size={{ base: "md", sm: "lg", lg: "lg2" }}
      fontWeight={600}
      color='black.1000'
      mr={2}
    >
      {titles || "Whimstay Admin Portal"}
    </Text>
  )
}

export default Title
