import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
  EmailContent,
  GetConfirmationMailRequest,
} from "api/bcomBookingDashboard/getConfirmationMailApi"
import { GetInvoiceBookingNoApiRequest } from "api/bookingDashboard/getInvoicebyBookingNoApi"
import { BannerListTypeRequests } from "api/heroBanner/getBannerListApi"
import { PropertyTypeRequest } from "api/propertyManagement/getPropertyTypeWiseDataApi"
import { RootState } from "app/store"
import { bcomBookingClient } from "services/clients/bcomBookingClient"
import { bookingClient } from "services/clients/bookingClient"
import { apiCommonClient } from "services/clients/commonClient"
import { heroBannerClient } from "services/clients/heroBannerClient"
import { apiPermissionsClient } from "services/clients/permissionsClient"
import { propertyManagementClient } from "services/clients/propertyManagemnetClient"
import { markStates } from "utils/misc"

import { UIInitialState } from "./types"

export const getPMCListThunk = createAsyncThunk("ui/getPMCList", async () => {
  const response = await apiCommonClient.getPMCList()
  return response.data
})

export const getPMSListThunk = createAsyncThunk("ui/getPMSList", async () => {
  const response = await apiCommonClient.getPMSList()
  return response.data
})

export const getRoleDetailsThunk = createAsyncThunk(
  "ui/getRoleDetails",
  async () => {
    const response = await apiPermissionsClient.getRoleApi()
    return response.data.data
  },
)

export const getInvoiceBookingThunk = createAsyncThunk(
  "ui/getInvoiceBookingNo",
  async (request: GetInvoiceBookingNoApiRequest) => {
    const response = await bookingClient.getInvoiceBookingNo(request)
    return response.data.data
  },
)

export const getPropertyTypeWiseThunk = createAsyncThunk(
  "ui/getPropertyTypeWiseData",
  async (request: PropertyTypeRequest) => {
    const response = await propertyManagementClient.getPropertyTypeWiseData(
      request,
    )
    return response.data.data
  },
)

export const getBannerListWiseThunk = createAsyncThunk(
  "ui/getBannerListWiseThunk",
  async (request: BannerListTypeRequests) => {
    const response = await heroBannerClient.getBannerLists(request)
    return response.data.data
  },
)

export const getMailBookingDataThunk = createAsyncThunk(
  "ui/getMailBookingDataThunk",
  async (request: GetConfirmationMailRequest) => {
    const response = await bcomBookingClient.getConfirmationMail(request)
    return response.data
  },
)

const initialState: UIInitialState = {
  isSidebarOpen: true,
  PMCList: [],
  PMSList: [],
  roleDetails: [],
  invoiceData: [],
  propertyData: [],
  bannerTableData: [],
  confirmationMailData: {},
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    resetUIStore: (state) => {
      markStates(initialState, state)
    },
    markUI: (state, action: PayloadAction<Partial<UIInitialState>>) => {
      markStates(action.payload, state)
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPMCListThunk.fulfilled, (state, action) => {
      state.PMCList = [{ name: "ALL", id: "ALL" }].concat(
        action.payload.map((pmc) => ({
          name: pmc.companyName,
          id: pmc.pmcId,
        })),
      )
    })
    builder.addCase(getPMCListThunk.rejected, (state) => {
      state.PMCList = []
    })

    builder.addCase(getPMSListThunk.fulfilled, (state, action) => {
      state.PMSList = [{ name: "ALL", id: "ALL" }].concat(
        action?.payload?.data?.map((pms: string) => ({
          name: pms.toUpperCase(),
          id: pms,
        })),
      )
    })
    builder.addCase(getPMSListThunk.rejected, (state) => {
      state.PMSList = []
    })
    builder.addCase(getRoleDetailsThunk.fulfilled, (state, action) => {
      state.roleDetails = action.payload
    })
    builder.addCase(getRoleDetailsThunk.rejected, (state) => {
      state.roleDetails = []
    })
    builder.addCase(getInvoiceBookingThunk.fulfilled, (state, action) => {
      state.invoiceData = action.payload
    })
    builder.addCase(getInvoiceBookingThunk.rejected, (state) => {
      state.invoiceData = []
    })
    builder.addCase(getPropertyTypeWiseThunk.fulfilled, (state, action) => {
      state.propertyData = action.payload
    })
    builder.addCase(getPropertyTypeWiseThunk.rejected, (state) => {
      state.propertyData = []
    })
    builder.addCase(getBannerListWiseThunk.fulfilled, (state, action) => {
      state.bannerTableData = action.payload
    })
    builder.addCase(getBannerListWiseThunk.rejected, (state) => {
      state.bannerTableData = []
    })

    builder.addCase(getMailBookingDataThunk.fulfilled, (state, action) => {
      state.confirmationMailData = action.payload
    })
    builder.addCase(getMailBookingDataThunk.rejected, (state) => {
      state.confirmationMailData = {}
    })
  },
})

export const { markUI, resetUIStore, toggleSidebar } = uiSlice.actions

export const selectIsSidebarOpen = (state: RootState) =>
  state.uiReducer.isSidebarOpen

export const selectPMCList = (state: RootState) => state.uiReducer.PMCList

export const selectPMSList = (state: RootState) => state.uiReducer.PMSList

export const selectRoleDetails = (state: RootState) =>
  state.uiReducer.roleDetails

export const selectInvoiceBookingDetails = (state: RootState) =>
  state.uiReducer.invoiceData

export const selectPropertyData = (state: RootState) =>
  state.uiReducer.propertyData

export const selectBannerData = (state: RootState) =>
  state.uiReducer.bannerTableData

export const selectConfirmationMailData = (
  state: RootState,
): { [key: string]: EmailContent[] } =>
  state.uiReducer.confirmationMailData as { [key: string]: EmailContent[] }

export default uiSlice.reducer
