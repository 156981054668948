import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

export interface TravellerInfo {
  address?: string
  addressId?: number
  bookingNumber?: string
  city?: string
  country?: string
  createdDt?: number
  emailId?: string
  firstName?: string
  lastName?: string
  mobileNumber?: string
  nickName?: string
  pronouns?: string
  state?: string
  zipCode?: string
}

export interface AddNewGuestType {
  emailId: string
  error: string
  firstName: string
  id: number
  isDisable: boolean
  lastName: string
}

export interface BookingGuestInfoResponseData extends ResponseData {
  bookingData: {
    adultNumber: number
    bookingAddress: TravellerInfo
    bookingId: number
    bookingNumber: string
    childNumber: number
    differentBillingAddress: boolean
    isConfirmedByPmc: boolean
  }
  duplicateEmailIds?: string
}

export interface BookingGuestInfoResponses extends AxiosResponse {
  data: BookingGuestInfoResponseData
}

export interface GuestInfoParams {
  bookingNumber?: string | null
  guestInfo?: AddNewGuestType[]
}

export interface BookingGuestInfoRequests extends Partial<AxiosRequestConfig> {
  params: GuestInfoParams
}

export function getBookingGuestInfoApi(
  { params, ...config }: BookingGuestInfoRequests,
  axiosInstance: AxiosInstance,
): Promise<BookingGuestInfoResponses> {
  return axiosInstance({
    data: params,
    method: "post",
    url: `booking/booking-guest-info`,
    ...config,
  }) as Promise<BookingGuestInfoResponses>
}
