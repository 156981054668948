import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { SingleBookingType } from "./getSingleBookingDetailApi"

interface CancelBookingPayoutDetailResponse extends AxiosResponse {
  data: SingleBookingType
  message: string
}

export interface CancelBookingPayoutDetailRequest
  extends Partial<AxiosRequestConfig> {
  data: {
    bookingNumber: string | number | undefined
    cancel: boolean
    note: string | null
    reason: string | null
    refundAmount: number | undefined
    revert: boolean
  }
}

export function cancelBookingPayoutDetailApi(
  { data, ...config }: CancelBookingPayoutDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<CancelBookingPayoutDetailResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `booking/cancel-host-payout`,
    ...config,
  }) as Promise<CancelBookingPayoutDetailResponse>
}
