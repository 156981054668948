import { ComponentStyleConfig } from "@chakra-ui/react"

const Button: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    color: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    borderRadius: "20px",
  },

  sizes: {
    sm: { px: "12px", py: "8px", fontSize: "16px", lineHeight: "17px" },
    md: { px: "20px", py: "8px", fontSize: "16px", lineHeight: "16px" },
    lg: { px: "24px", py: "8px", fontSize: "16px", lineHeight: "24px" },
  },

  variants: {
    primary: {
      bgGradient:
        "linear-gradient(90deg, #006F80 0.01%, #006F80 26.47%, #003740 99.66%)",

      _hover: {
        borderColor: "transparent",
        boxShadow:
          "0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1)",
        bgGradient: "linear-gradient(-90deg,#006f80,#003740 136.52%)",
        _disabled: {
          bgGradient:
            "linear-gradient(90deg, #006F80 0%, #006F80 0.01%, #003740 99.66%)",
        },
      },

      _active: {
        boxShadow:
          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      },
      _focus: {
        boxShadow:
          "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    ghost: {
      color: "#000000",
      bgGradient: "transparent",
      _hover: {
        bg: "transparent",
        color: "secondary.500",
      },
      _focus: {
        bg: "transparent",
        color: "secondary.500",
      },
      _active: {
        bg: "transparent",
        color: "secondary.500",
        fontWeight: "600",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    outline: {
      border: "1px solid #B1E4E3",
      borderRadius: "74px",
      color: "black.1000",
      bgGradient: "transparent",
      _hover: {
        bg: "transparent",
        color: "secondary.500",
      },
      _focus: {
        bg: "transparent",
        color: "secondary.500",
      },
      _active: {
        bg: "transparent",
        color: "secondary.500",
        fontWeight: "600",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    secondary: {
      color: "secondary.500",
      bgGradient: "transparent",
      border: "2px solid",
      borderColor: "secondary.500",
      _hover: { bg: "secondary.25" },
      _focus: {},
      _active: {
        bg: "secondary.500",
        color: "#ffffff",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    secondaryGray: {
      color: "#fff",
      bg: "grayText",
      borderRadius: "20px",
      _hover: {
        _disabled: {
          bg: "#999A9A",
          color: "#ffffff",
        },
      },
      _focus: { bg: "grayText", color: "#ffffff" },
      _active: { bg: "grayText", color: "#ffffff" },
      _disabled: {
        opacity: 0.5,
      },
    },

    tertiary: {
      bg: "secondary.500",
      fontWeight: "medium",
      fontSize: "sm",
      border: "2px solid",
      borderColor: "secondary.500",
      _hover: { bg: "secondary.400" },
    },

    link: {
      color: "#000000",
      textDecorationLine: "underline",
    },
    menuItem: {
      color: "#000000",
      w: "full",
      py: "10px",
      px: "12px",
      borderRadius: "0px",
      _active: { bg: "secondary.500", color: "#ffffff" },
      _focus: { bg: "secondary.500", color: "#ffffff" },
      _hover: { bg: "secondary.25" },
    },

    checkboxItem: {
      color: "#000000",
      w: "full",
      py: "10px",
      px: "12px",
      borderRadius: "0px",
      _active: { bg: "#DEEBFF", color: "#000000" },
      _focus: { bg: "#DEEBFF", color: "#000000" },
      _hover: { bg: "#DEEBFF" },
    },

    navItem: {
      color: "grayText",
      bg: "offWhite",
      w: "full",
      pl: "12px",
      justifyContent: "flex-start",
      borderRadius: "0px",
      transition: "all 0.1s ease-in 0s",
      _hover: {
        bg: "glacierColor",
        borderRadius: "8px",
        _disabled: {
          bg: "#fff",
        },
      },

      _active: {
        borderRadius: "8px",
        bg: "glacierColor !important",
        "svg path": {
          stroke: "secondary.1000",
        },
        p: {
          color: "secondary.1000",
        },
      },
      _focus: {
        bg: "#0002",
        boxShadow: "none",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    icon: {
      color: "grayText",
      bg: "transparent",
      borderRadius: "1000px",
      transition: "all 0.1s ease-in 0s",
      _hover: {
        bg: "secondary.1000",
        _disabled: {
          bg: "#fff",
        },
      },

      _active: {
        bg: "secondary.1000",
      },
      _focus: {
        boxShadow: "none",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    tableIcon: {
      color: "rgb(0, 0, 0)",
      borderRadius: "1000px",
      transition: "all 0.1s ease-in 0s",
      _hover: {
        transform: "scale(1.1)",
      },
    },
    dropDownButton: {
      color: "#000000",
      bg: "transparent",
      border: "2px solid",
      borderColor: "secondary.500",
      _hover: { bg: "secondary.25", color: "secondary.500" },
      _active: {
        color: "secondary.500",
        fontWeight: "600",
      },
      _disabled: {
        opacity: 0.5,
      },

      _focus: {
        borderColor: "secondary.200",
      },
    },
  },

  // default values for `size` and `variant`
  defaultProps: {
    size: "md",
    variant: "primary",
  },
}

export default Button
