import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface SendBcomConfirmationMailResponse extends AxiosResponse {
  data: string
}

export interface SendBcomConfirmationMailRequest
  extends Partial<AxiosRequestConfig> {
  data: {
    houseRules?: string[] | undefined
    importantInformation?: string[] | undefined
    hostPhone?: string | undefined
    sendEmail: boolean
    whimstayBookingConfirmationNumber: string
  }
}

export function sendBcomConfirmationMailApi(
  { data, ...config }: SendBcomConfirmationMailRequest,
  axiosInstance: AxiosInstance,
): Promise<SendBcomConfirmationMailResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `/admin/confirmationMail/sendBcomConfirmationMail`,
    ...config,
  })
}
