import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface GetCallerVerifyOtpRequest extends Partial<AxiosRequestConfig> {
  params: {
    otp: string
  }
}

interface GetCallerVerifyOtpResponseData {
  firstName: string
  isEmailVerify: boolean
  message: string
  mobileVerified: boolean
  status: number
  userId: number
}

export interface GetCallerVerifyOtpResponse extends AxiosResponse {
  data: GetCallerVerifyOtpResponseData
}

export function getCallerVerifyUserOtpApi(
  { params, ...config }: GetCallerVerifyOtpRequest,
  axiosInstance: AxiosInstance,
): Promise<GetCallerVerifyOtpResponse> {
  return axiosInstance({
    params,
    method: "get",
    url: "auth/mobile/verify",
    ...config,
  })
}
