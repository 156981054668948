import { Box, IconButton, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import FacebookIcon from "./Icons/social/FacebookIcon"
import InstagramIcon from "./Icons/social/InstagramIcon"
import LinkedinIcon from "./Icons/social/LinkedinIcon"

const Footer = ({ isIndexPage = false }: { isIndexPage: boolean }) => {
  return (
    <Box
      position={"sticky"}
      top='calc(100vh - 40px)'
      w='full'
      px='4'
      py={isIndexPage ? "10" : "1"}
      as='footer'
      bg='offWhite'
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      borderTop='1px solid #EEE'
      gap='4'
      flexWrap={"wrap"}
      flexDir={isIndexPage ? "column-reverse" : "row"}
    >
      <Text>© Whimstay, Inc. All Rights Reserved.</Text>
      <Box display={"flex"} gap='2' mr={isIndexPage ? "0" : "6"}>
        <IconButton
          aria-label='Facebook'
          as={Link}
          size='sm'
          variant={"icon"}
          _hover={{
            bg: "transparent",
          }}
          target='_blank'
          to={"https://www.facebook.com/whimstay"}
          icon={<FacebookIcon />}
        />
        <IconButton
          aria-label='Instagram'
          as={Link}
          size='sm'
          variant={"icon"}
          _hover={{
            bg: "transparent",
          }}
          target='_blank'
          to={"https://www.instagram.com/whimstay/"}
          icon={<InstagramIcon />}
        />
        <IconButton
          aria-label='Linkedin'
          as={Link}
          size='sm'
          variant={"icon"}
          _hover={{
            bg: "transparent",
          }}
          target='_blank'
          to={"https://www.linkedin.com/company/whimstay"}
          icon={<LinkedinIcon mb={1} />}
        />
      </Box>
    </Box>
  )
}

export default Footer
