import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { markStates } from "utils/misc"

import { ModalInitialState } from "./types"

const initialState: ModalInitialState = {
  modalType: "",
  bookingData: null,
  rowData: null,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openCancelBookingModalFromTable: (
      state,
      action: PayloadAction<Partial<ModalInitialState>>,
    ) => {
      markStates(action.payload, state)
    },
    openEditBookingModalFromTable: (
      state,
      action: PayloadAction<Partial<ModalInitialState>>,
    ) => {
      markStates(action.payload, state)
    },
    closeBookingModal: (state) => {
      state.modalType = ""
      state.bookingData = null
      state.rowData = null
    },
  },
})

export const {
  openEditBookingModalFromTable,
  openCancelBookingModalFromTable,
  closeBookingModal,
} = modalSlice.actions

export const selectModalType = (state: RootState) =>
  state.modalReducer.modalType

export const selectRowData = (state: RootState) => state.modalReducer.rowData

export const selectBookingData = (state: RootState) =>
  state.modalReducer.bookingData

export default modalSlice.reducer
