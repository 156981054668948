import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { InvoiceType } from "api/bookingDashboard/getAllInvoiceApi"

export interface UpdateInvoiceType {
  amount: number
  fees: number
  dueDate: string
  texes: number
  travellerName: string
  stripeInvoiceStatus: string
  discountPercentage: number
  nighlyRate: number
  invoiceType: string
  invoiceItem: string
  invoiceId: undefined | number
  dateOfPaymentRequest: string
  isPriceBreakDown: boolean
  email: string
  memoDescription: string
  bookingNumber: string
}

interface InvoiceBookingResponse extends AxiosResponse {
  data: InvoiceType
  message: string
}

export interface InvoiceBookingRequest extends Partial<AxiosRequestConfig> {
  data: {
    invoiceId: undefined | number
    travellerName: string
    email: string
    invoiceType: string
    invoiceItem: string
    bookingNumber: string
    dateOfPaymentRequest: string
    memoDescription: string
    isPriceBreakDown: boolean
    amount: number
    dueDate: string
    stripeInvoiceStatus: string
    nighlyRate: number
    fees: number
    texes: number
    discountPercentage: number
  }
}

export function updateInvoiceBookingApi(
  { data, ...config }: InvoiceBookingRequest,
  axiosInstance: AxiosInstance,
): Promise<InvoiceBookingResponse> {
  return axiosInstance({
    data,
    method: "put",
    url: `invoice`,
    ...config,
  }) as Promise<InvoiceBookingResponse>
}
