import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { UserRoleType } from "configs/permissions"
import { Pageable, ResponseData, SortDirectionType } from "services/common"

export interface AdminUserDetailType {
  dialerCode: string
  emailId: string
  firstName: string
  lastName: string
  mobileno1: string
  userId: number
  userRole: UserRoleType
}

export interface GetPaginatedAdminUserDetailResponseDataData extends Pageable {
  content: AdminUserDetailType[]
}

interface GetPaginatedAdminUserDetailResponseData extends ResponseData {
  data: GetPaginatedAdminUserDetailResponseDataData
}

interface GetPaginatedAdminUserDetailResponse extends AxiosResponse {
  data: GetPaginatedAdminUserDetailResponseData
}

export interface GetPaginatedAdminUserDetailRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyWord: string
    noOfRecords: number
    pageNo: number
    sortBy: string
    sortDirection: SortDirectionType
  }
}

export function getPaginatedAdminUserDetailApi(
  { params, ...config }: GetPaginatedAdminUserDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedAdminUserDetailResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/admin-user`,
    ...config,
  })
}
