import { ComponentStyleConfig } from "@chakra-ui/react"

const Text: ComponentStyleConfig = {
  // The styles all Text have in common
  baseStyle: {
    color: "textColor",
    fontFamily: "Montserrat,sans-serif",
    fontWeight: 400,
  },

  sizes: {
    xs: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    sm: {
      fontSize: "14px",
      lineHeight: "1.5",
    },
    md: {
      fontSize: "16px",
      lineHeight: "1.2",
    },
    lg: {
      fontSize: "18px",
      lineHeight: "1.2",
    },
    lg2: {
      fontSize: "20px",
      lineHeight: "1.2",
    },
    lg3: {
      fontSize: "24px",
      lineHeight: "1.2",
    },
    xl: {
      fontSize: "28px",
      lineHeight: "1.2",
    },
    xxl: {
      fontSize: "32px",
      lineHeight: "1.2",
    },
  },

  variants: {
    black: {
      color: "textColor",
    },
    secondary: {
      color: "secondary.500",
    },
    white: {
      color: "#ffffff",
    },
    gray: {
      color: "#606161",
    },
    darkGray: {
      color: "#5d5d5d",
    },
    navColor: {
      color: "grayText",
    },
  },

  // default values for `size` and `variant`
  defaultProps: {
    size: "md",
    variant: "black",
  },
}

export default Text
