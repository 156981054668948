import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface ModifyNote {
  modifiedId: number
  note: string
  type: string
  isCreateTravelerPayment: boolean
  isCreateHostPayout: boolean
  travelerChargeAmount: number
  payoutAmount: number
  modifyBy: number
}

export interface PayoutNoteType {
  transactionId: number
  bookingNo: string
  currency: string
  isActive: boolean
  isTransactionReverse: boolean
  isTransactionSucessfull: boolean
  logCharacter: null | string
  paymentDate: null | string
  status: string
  pmcId: string
  stripeTranferId: null | string
  transferAmount: number
  transferSendDate: string
  transferType: string
  transferReversedAmount: number
  note: null | string
  reason: string
  modifiedId: number | null
}

export interface BookingNotesDataType {
  bookingId: number
  bookingNumber: string
  cancelReasone: null | string
  cancelReasoneNote: null | string
  cancelBy: null | string
  modifyNote: ModifyNote[]
  payoutNote: PayoutNoteType[]
}

interface BookingNotesResponses extends AxiosResponse {
  data: BookingNotesDataType
}

export interface BookingNotesRequests extends Partial<AxiosRequestConfig> {
  bookingNo: string | undefined
}

export function getBookingNotesApi(
  { bookingNo, ...config }: BookingNotesRequests,
  axiosInstance: AxiosInstance,
): Promise<BookingNotesResponses> {
  return axiosInstance({
    method: "get",
    url: `newadmin/getbookings-notes/${bookingNo || ""}`,
    ...config,
  })
}
