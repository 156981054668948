import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface ForceCancelBookingData {
  message: string
}

interface ForceCancelBookingResponses extends AxiosResponse {
  data: ForceCancelBookingData
}

export interface ForceCancelBookingRequests
  extends Partial<AxiosRequestConfig> {
  data: {
    bookingNo: string | undefined
  }
}

export function getForceCancelBookingApi(
  { data, ...config }: ForceCancelBookingRequests,
  axiosInstance: AxiosInstance,
): Promise<ForceCancelBookingResponses> {
  return axiosInstance({
    method: "post",
    url: `booking/cancel-booking/${data?.bookingNo || ""}`,
    ...config,
  })
}
