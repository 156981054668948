import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { SingleBookingType } from "./getSingleBookingDetailApi"

interface ModifyBookingResponse extends AxiosResponse {
  data: SingleBookingType
  message: string
}

export interface ModifyBookingRequest extends Partial<AxiosRequestConfig> {
  data: {
    bookingNumber: string | undefined
    modifyBookingType?: string
    travelerPayments: {
      id: number
      paymentAmount: number
      userId: number | undefined
    }[]
    hostPayouts: {
      id: number
      paymentAmount: number
    }[]
    cancel?: boolean
    reason?: string | undefined
    refundResionType?: string | undefined
    refundable?: boolean
    checkInDate?: string | undefined
    checkOutDate?: string | undefined
    adultNumber?: number
    childNumber?: number
    petNumber?: number
    note?: string
    rentAmount?: number
    hostFees?: number
    taxes?: number
    travalerInsurance?: number
    additnalWhimstayHostServiceFees?: number | undefined
    whimstayTravelerServiceFeeAmount?: number | undefined
    hostPayoutAmount?: number
    travelerPaymentAmount?: number
    payoutSendDate?: string | undefined
    createAdditnalPayment?: boolean
    travelerPayment?: boolean
    hostPayout?: boolean
    refundReason?: string
    payoutReason?: string
    splitUsers: {
      additionalAmount: number
      splitUserId: number
    }[]
    sendEmail: boolean
  }
}

export function modifyBookingApi(
  { data, ...config }: ModifyBookingRequest,
  axiosInstance: AxiosInstance,
): Promise<ModifyBookingResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `booking/modifybooking`,
    ...config,
  }) as Promise<ModifyBookingResponse>
}
