import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus } from "services/common"

export interface PropertyManagementType {
  apiStatus: ApiStatus
  data: string
}

interface DeletePropertyManagementResponse extends AxiosResponse {
  data: PropertyManagementType
}

export interface DeletePropertyManagementRequest
  extends Partial<AxiosRequestConfig> {
  data: string[]
}

export function deletePropertyManagementApi(
  { data, ...config }: DeletePropertyManagementRequest,
  axiosInstance: AxiosInstance,
): Promise<DeletePropertyManagementResponse> {
  return axiosInstance({
    method: "delete",
    url: `newadmin/property-management/by-id`,
    ...config,
    data,
  })
}
