import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { SingleBookingType } from "./getSingleBookingDetailApi"

interface CancelBookingResponse extends AxiosResponse {
  data: SingleBookingType
  message: string
}

export interface CancelBookingRequest extends Partial<AxiosRequestConfig> {
  data: {
    bookingNumber: string | undefined
    travelerPayments: {
      id: number
      paymentAmount: number
      userId: number | undefined
    }[]
    cancel: boolean
    reason: string | undefined
    refundResionType: string | undefined
    refundable: boolean
    cancellationFee?: number
  }
}

export function cancelBookingApi(
  { data, ...config }: CancelBookingRequest,
  axiosInstance: AxiosInstance,
): Promise<CancelBookingResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `booking/cancel`,
    ...config,
  }) as Promise<CancelBookingResponse>
}
