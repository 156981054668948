import { PropsWithChildren } from "react"

import { Box } from "@chakra-ui/react"
import { Navigate } from "react-router-dom"

import { ModuleType } from "configs/permissions"
import useModulePermission from "hooks/useModulePermission"

const PermittedContentOnly = ({
  module,
  children,
}: PropsWithChildren<{
  module: ModuleType
}>) => {
  const { hasAccessToModule } = useModulePermission(module)
  if (hasAccessToModule === undefined) return <Box>{/*<Spinner />*/}</Box>
  if (hasAccessToModule === false) return <Navigate to={"/"} />

  return <>{children} </>
}

export default PermittedContentOnly
