import Layout from "components/Layout"

import Providers from "./Providers"
import CustomRouter from "./Router"

function App() {
  return (
    <Providers>
      <Layout>
        <CustomRouter />
      </Layout>
    </Providers>
  )
}

export default App
