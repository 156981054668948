import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus } from "services/common"

interface BypassIdVerificationResponse extends AxiosResponse {
  data: {
    data: boolean
    message: string
    apiStatus: ApiStatus
  }
}

export interface BypassIdVerificationRequest
  extends Partial<AxiosRequestConfig> {
  bookingNumber: string
}

export function bypassIdVerificationApi(
  { bookingNumber, ...config }: BypassIdVerificationRequest,
  axiosInstance: AxiosInstance,
): Promise<BypassIdVerificationResponse> {
  return axiosInstance({
    method: "post",
    url: `/admin/booking/capture-payment?bookingNumber=${bookingNumber}`,
    ...config,
  }) as Promise<BypassIdVerificationResponse>
}
