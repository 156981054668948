import { useState } from "react"

import { Button, Center, Divider, Input, Text } from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"

const OtpForm = ({
  onRetry,
  onSubmit,
  buttonTitle,
}: {
  onRetry: () => void
  onSubmit: (otp: string) => void
  buttonTitle: string
}) => {
  const [errorMessage, setErrorMessage] = useState("")
  function validateOtp(number: string) {
    if (number.length < 6) {
      setErrorMessage("Enter 6 digit otp")
      return false
    } else {
      setErrorMessage("")
      return true
    }
  }

  return (
    <>
      <Text fontFamily={"'Lubalin Graph Std Demi', sans-serif"}>
        We Sent You A Text
      </Text>
      <Text mt={2} fontSize={"14px"}>
        Please enter the verification code below
      </Text>
      <Formik
        initialValues={{ otp: "" }}
        validateOnChange={false}
        validationSchema={Yup.object({
          otp: Yup.string()
            .max(6, "Enter 6 digit otp")
            .min(6, "Enter 6 digit otp")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (validateOtp(values.otp)) {
            onSubmit(values.otp)
            setSubmitting(false)
          }
        }}
      >
        <Form>
          <Field
            as={Input}
            name='otp'
            type='number'
            mt='4'
            placeholder='XXXXXX'
            maxLength={6}
            max={999999}
            borderRadius='20px'
            border='1px solid #EEE'
          />
          <Text color='red' fontSize='14px' mt='4px'>
            {errorMessage}
          </Text>
          <Button w='full' size='lg' mt='6' type='submit'>
            {buttonTitle}
          </Button>
          <Divider my='4' />
          <Center gap='2' mb={4}>
            <Text display={"inline-block"} fontSize={"14px"}>
              {"Didn't get a code?"}
            </Text>
            <Button
              variant={"link"}
              fontSize={"14px"}
              color='secondary.400'
              textDecor={"unset"}
              _hover={{ color: "black" }}
              onClick={onRetry}
            >
              {"Let's try that again."}
            </Button>
          </Center>
        </Form>
      </Formik>
    </>
  )
}

export default OtpForm
