import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface SendOtpRequest extends Partial<AxiosRequestConfig> {
  params: {
    code: string
    number: string
  }
}

export interface SendOtpResponse extends AxiosResponse {
  data: {
    message: string
  }
}

export function sendOtpApi(
  { params, method = "get", ...config }: SendOtpRequest,
  axiosInstance: AxiosInstance,
): Promise<SendOtpResponse> {
  let url = ""
  if (params.code && params.number) {
    url = `auth/mobile/${params.code}/${params.number}/otp`
  }
  return axiosInstance({
    method,
    url,
    ...config,
  })
}
