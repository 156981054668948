import { AxiosInstance } from "axios"

import { addPropertyDataApi } from "api/propertyManagement/addPropertyDataApi"
import { deletePropertyManagementApi } from "api/propertyManagement/deletePropertyManagementApi"
import { getPropertyPreviewDataApi } from "api/propertyManagement/getPropertyPreviewDataApi"
import { getPropertyTypeApi } from "api/propertyManagement/getPropertyTypeApi"
import { getPropertyTypeWiseDataApi } from "api/propertyManagement/getPropertyTypeWiseDataApi"
import { updatePropertyConfigApi } from "api/propertyManagement/updatePropertyConfigApi"
import defaultAxiosInstance from "services/client"

export class PropertyTypeClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getPropertyType() {
    return getPropertyTypeApi(this.axiosInstance)
  }

  getPropertyTypeWiseData(
    request: Parameters<typeof getPropertyTypeWiseDataApi>[0],
  ) {
    return getPropertyTypeWiseDataApi(request, this.axiosInstance)
  }

  getPropertyPreviewData(
    request: Parameters<typeof getPropertyPreviewDataApi>[0],
  ) {
    return getPropertyPreviewDataApi(request, this.axiosInstance)
  }

  addPropertyData(request: Parameters<typeof addPropertyDataApi>[0]) {
    return addPropertyDataApi(request, this.axiosInstance)
  }

  deletePropertyManagement(
    request: Parameters<typeof deletePropertyManagementApi>[0],
  ) {
    return deletePropertyManagementApi(request, this.axiosInstance)
  }

  updatePropertyConfig(request: Parameters<typeof updatePropertyConfigApi>[0]) {
    return updatePropertyConfigApi(request, this.axiosInstance)
  }
}

export const propertyManagementClient = new PropertyTypeClient(
  defaultAxiosInstance,
)
