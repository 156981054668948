import { Icon } from "@chakra-ui/react"

const FacebookIcon = () => {
  return (
    <Icon
      width='10'
      height='18'
      viewBox='0 0 10 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIwMSIgaGVpZ2h0PSIxNS42MjEiIHZpZXdCb3g9IjAgMCA4LjIwMSAxNS42MjEiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCA0MjMiIGQ9Ik01LjQ2OCA4Ljk4Mkg3LjQybC43OC0 1'
        clipPath='url(#clip0_328_5059)'
      >
        <path
          id='Path 423'
          d='M6.30084 10.3498H8.55016L9.44896 6.75004H6.30084V4.95476C6.30084 4.03293 6.30084 3.15488 8.10075 3.15488H9.44896V0.125495C8.59509 0.0401644 7.73743 -0.00175626 6.8793 -0.000105184C6.31528 -0.0423094 5.74882 0.0385794 5.21915 0.23696C4.68948 0.435341 4.20925 0.746473 3.81173 1.14881C3.41421 1.55115 3.10888 2.03508 2.9169 2.5671C2.72492 3.09912 2.65086 3.6665 2.69987 4.22997V6.75004H0V10.3498H2.69987V17.9999H6.29969L6.30084 10.3498Z'
          fill='#606161'
        />
      </g>
      <defs>
        <clipPath id='clip0_328_5059'>
          <rect width='9.45011' height='18' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default FacebookIcon
