import { lazy, useEffect, Suspense } from "react"

import { Box, Spinner } from "@chakra-ui/react"
import { Route, Routes, Navigate, useNavigate } from "react-router-dom"

import PermittedContentOnly from "components/PermittedContentOnly"
import ProtectedComponent from "components/ProtectedComponents"
import { useAppSelector } from "hooks/store/hooks"
import { selectIsUserLoggedIn } from "stores/auth/authSlice"
import { Endpoints } from "types"

const Index = lazy(() => import("pages/Index"))
const UserDashboardPage = lazy(() => import("pages/UserDashboardPage"))
const BookingDashboardPage = lazy(() => import("pages/BookingPage"))
const EditBooking = lazy(() => import("pages/BookingPage/EditBookingDetails"))
const InvoiceBooking = lazy(
  () => import("pages/BookingPage/Invoice/InvoiceIndex"),
)
const NewBookingDashboardPage = lazy(() => import("pages/NewBookingPage"))
const EditNewBooking = lazy(
  () => import("pages/NewBookingPage/EditBookingDetails"),
)
const BcomBookingDashboard = lazy(() => import("pages/BcomBookingDashboard"))
const BcomBookingDetails = lazy(
  () => import("pages/BcomBookingDashboard/BcomBookingDetails"),
)

const CompanyDashboard = lazy(() => import("pages/CompanyDashboard"))
const ButtonPage = lazy(() => import("pages/comps/ButtonPage"))
const DropDownPage = lazy(() => import("pages/comps/DropDownPage"))
const InputPage = lazy(() => import("pages/comps/InputPage"))
const SearchableDropDownPage = lazy(
  () => import("pages/comps/SearchableDropDownPage"),
)
const TablePage = lazy(() => import("pages/comps/Table/TablePage"))
const TextPage = lazy(() => import("pages/comps/TextPage"))
const SendEmailsPage = lazy(() => import("pages/SendEmails"))
const HeroBannerPage = lazy(() => import("pages/HeroBannerPage"))
const PropertyManagementPage = lazy(
  () => import("pages/PropertyManagementPage"),
)
const EventsPage = lazy(() => import("pages/EventsPage"))
const FaqPage = lazy(() => import("pages/FaqPage"))
const HostPayoutDashboardPage = lazy(() => import("pages/HostPayoutPage"))
const ManageTokenPage = lazy(() => import("pages/ManageTokenPage"))
const PermissionsPage = lazy(() => import("pages/PermissionsPage"))
const ManageUsersPage = lazy(() => import("pages/ManageUsersPage"))
const RegionalLandingPage = lazy(() => import("pages/RegionalLandingPage"))
const OnboardingPage = lazy(() => import("pages/Onboarding/OnboardingPage"))
const PMSFeesManageTokenPage = lazy(() => import("pages/PMSFeesManagePage"))
const ManagePromoCodesPage = lazy(() => import("pages/ManagePromoCodePage"))
const RedeemPromocodePage = lazy(
  () => import("pages/ManagePromoCodePage/RedeemPromocodePage"),
)
const PropertyDashboardPage = lazy(() => import("pages/PropertyDashboardPage"))
const CallerVerificationPage = lazy(
  () => import("pages/CallerVerificationPage"),
)
const ReportsPage = lazy(() => import("pages/ReportsPage"))
const PropertyDiscount = lazy(
  () => import("pages/WimstayDiscount/PropertyDiscount"),
)
const CompanyDiscount = lazy(
  () => import("pages/WimstayDiscount/CompanyDiscount"),
)

const Router = () => {
  const isLoggedIn = useAppSelector(selectIsUserLoggedIn)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", { replace: true })
    }
  }, [isLoggedIn, navigate])

  return (
    <Suspense
      fallback={
        <Box textAlign={"center"} marginY={"100px"}>
          <Spinner />
        </Box>
      }
    >
      <Routes>
        <Route
          path={Endpoints.index}
          element={
            !isLoggedIn ? (
              <Index />
            ) : (
              <Navigate to={Endpoints.propertyDashboard} replace />
            )
          }
        />
        <Route
          path={Endpoints.propertyDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='PropertyDashboard'>
                <PropertyDashboardPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.callerVerification}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='CallerVerification'>
                <CallerVerificationPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.userDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='UserDashboard'>
                <UserDashboardPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        {/* -----------start old booking file-------------*/}
        <Route
          path={Endpoints.bookingDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <BookingDashboardPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.bookingDashboardDetails}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <EditBooking />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.bookingInvoice}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <InvoiceBooking />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        {/* ----------- end old booking file-------------*/}
        <Route
          path={Endpoints.newBookingDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <NewBookingDashboardPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.newBookingDashboardDetails}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <EditNewBooking />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.bcomBookingDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <BcomBookingDashboard />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.bcomBookingDashboardDetails}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='BookingDashboard'>
                <BcomBookingDetails />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.companyDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='CompanyDashboard'>
                <CompanyDashboard />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.manageCompanyDiscount}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='WhimstayDiscount'>
                <CompanyDiscount />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.managePropertyDiscount}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='WhimstayDiscount'>
                <PropertyDiscount />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />

        <Route
          path={Endpoints.hostPayoutDashboard}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='HostPayoutDashboard'>
                <HostPayoutDashboardPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.faq}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='FAQ'>
                <FaqPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.reports}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='Reports'>
                <ReportsPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.onboarding}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='OnboardingDashboard'>
                <OnboardingPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.managePromoCodes}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='PromoCodeDashboard'>
                <ManagePromoCodesPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.redeemPromoCodes}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='PromoCodeDashboard'>
                <RedeemPromocodePage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.manageTokens}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='ManageTokens'>
                <ManageTokenPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.pmsFeesManage}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='PMSFeesManage'>
                <PMSFeesManageTokenPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.events}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='Events'>
                <EventsPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.sendEmails}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='SendEmails'>
                <SendEmailsPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.heroBanner}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='HeroConfiguration'>
                <HeroBannerPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.propertyManagement}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='HeroConfiguration'>
                <PropertyManagementPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.manageUsers}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='ManageUsers'>
                <ManageUsersPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.regionalLanding}
          element={
            <ProtectedComponent>
              <PermittedContentOnly module='HeroConfiguration'>
                <RegionalLandingPage />
              </PermittedContentOnly>
            </ProtectedComponent>
          }
        />
        <Route
          path={Endpoints.manageRole}
          element={
            <ProtectedComponent>
              {/*<PermittedContentOnly module='ManageUsers'>*/}
              <PermissionsPage />
              {/*</PermittedContentOnly>*/}
            </ProtectedComponent>
          }
        />
        <Route path={"/comps/btn"} element={<ButtonPage />} />
        <Route path={"/comps/table"} element={<TablePage />} />
        <Route path={"/comps/text"} element={<TextPage />} />
        <Route path={"/comps/input"} element={<InputPage />} />
        <Route
          path={"/comps/searchDropdown"}
          element={<SearchableDropDownPage />}
        />
        <Route path={"/comps/dropdown"} element={<DropDownPage />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </Suspense>
  )
}

export default Router
