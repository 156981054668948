import {
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
} from "@chakra-ui/react"

// Foundational style overrides
// Global style overrides
import styles from "theme/styles"

// Component style overrides
import Button from "./components/button"
import Checkbox from "./components/checkbox"
import Form from "./components/form"
import Menu from "./components/menu"
import Table from "./components/table"
import Text from "./components/text"
import breakpoints from "./foundations/breakpoints"
import colors from "./foundations/colors"

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const customTheme = extendTheme(
  {
    config,
    styles,
    breakpoints,
    colors,
    fonts: {
      heading: "Montserrat",
      body: "Montserrat, 'sans-serif'",
    },
  },
  {
    components: {
      Button,
      Text,
      Table,
      Menu,
      Checkbox,
      Form,
    },
  },
  withDefaultColorScheme({
    colorScheme: "black",
    components: ["Progress"],
  }),
)

export default customTheme
