import { combineReducers } from "@reduxjs/toolkit"

import authReducer from "stores/auth/authSlice"
import modalReducer from "stores/modal/modalSlice"
import uiReducer from "stores/ui/uiSlice"

const rootReducer = combineReducers({
  authReducer,
  uiReducer,
  modalReducer,
})

export default rootReducer
