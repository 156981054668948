import { Icon } from "@chakra-ui/react"

const PromoCodesIcon = () => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M3.84995 8.61998C3.70399 7.9625 3.7264 7.27882 3.91511 6.63231C4.10381 5.98581 4.4527 5.39742 4.92942 4.9217C5.40614 4.44597 5.99526 4.09832 6.64216 3.91097C7.28905 3.72362 7.97278 3.70264 8.62995 3.84998C8.99166 3.28428 9.48995 2.81873 10.0789 2.49626C10.6678 2.17379 11.3285 2.00476 11.9999 2.00476C12.6714 2.00476 13.332 2.17379 13.921 2.49626C14.5099 2.81873 15.0082 3.28428 15.3699 3.84998C16.0281 3.702 16.713 3.72288 17.3609 3.91069C18.0089 4.09849 18.5988 4.44712 19.0758 4.92413C19.5528 5.40114 19.9014 5.99105 20.0892 6.63898C20.277 7.28691 20.2979 7.97181 20.1499 8.62998C20.7156 8.99168 21.1812 9.48998 21.5037 10.0789C21.8261 10.6679 21.9952 11.3285 21.9952 12C21.9952 12.6714 21.8261 13.3321 21.5037 13.921C21.1812 14.51 20.7156 15.0083 20.1499 15.37C20.2973 16.0271 20.2763 16.7109 20.089 17.3578C19.9016 18.0047 19.554 18.5938 19.0782 19.0705C18.6025 19.5472 18.0141 19.8961 17.3676 20.0848C16.7211 20.2735 16.0374 20.2959 15.3799 20.15C15.0187 20.7178 14.52 21.1854 13.9301 21.5093C13.3401 21.8332 12.678 22.003 12.0049 22.003C11.3319 22.003 10.6698 21.8332 10.0798 21.5093C9.48987 21.1854 8.99119 20.7178 8.62995 20.15C7.97278 20.2973 7.28905 20.2763 6.64216 20.089C5.99526 19.9016 5.40614 19.554 4.92942 19.0783C4.4527 18.6025 4.10381 18.0141 3.91511 17.3676C3.7264 16.7211 3.70399 16.0374 3.84995 15.38C3.27991 15.0192 2.81036 14.5201 2.485 13.9292C2.15963 13.3382 1.98901 12.6746 1.98901 12C1.98901 11.3254 2.15963 10.6617 2.485 10.0708C2.81036 9.4798 3.27991 8.98073 3.84995 8.61998Z'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 9L9 15'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9 9H9.01'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 15H15.01'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  )
}

export default PromoCodesIcon
