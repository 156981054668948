import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { SendOtpRequest } from "api/auth/sendOtp"
import { VerifyOtpRequest } from "api/auth/verifySignInOtpApi"
import { GetCallerSendOtpRequest } from "api/callerVerification/getCallerSendOtp"
import { GetCallerVerifyOtpRequest } from "api/callerVerification/getCallerVerifyUserOtp"
import { RootState } from "app/store"
import { apiAuthClient } from "services/clients/authClient"
import { callerVerificationClient } from "services/clients/callerVerificationClient"
import {
  removeTemporaryAuthTokenLocal,
  setAuthTokenLocal,
  setTemporaryAuthTokenLocal,
  setUserTokenLocal,
} from "utils/caller-local-storage"
import {
  cleanCookiesStorage,
  removeTemporaryAuthToken,
  setAuthToken,
  setTemporaryAuthToken,
  setUserCookie,
} from "utils/local-storage"
import { markStates } from "utils/misc"

import { AuthInitialState } from "./types"

export const sendOtpThunk = createAsyncThunk(
  "auth/sendOtp",
  async (request: SendOtpRequest, thunkAPI) => {
    try {
      const response = await apiAuthClient.sendOtp(request)
      setTemporaryAuthToken(response.headers.authorization)
      return response.data
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const callerVerificationSendOtpThunk = createAsyncThunk(
  "auth/sendOtp/api",
  async (request: GetCallerSendOtpRequest, thunkAPI) => {
    try {
      const response = await callerVerificationClient.getCallerSendOtpDetail(
        request,
      )
      setTemporaryAuthTokenLocal(response.headers.authorization)
      return response.data
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const getUserDetailsThunk = createAsyncThunk(
  "admin/getUserDetails",
  async () => {
    const response = await apiAuthClient.getUserDetails()
    return response.data
  },
)

export const verifyOtpThunk = createAsyncThunk(
  "auth/verifyOtp",
  async (request: VerifyOtpRequest, thunkAPI) => {
    try {
      const response = await apiAuthClient.verifyOtp(request)
      // store token in cookies
      setAuthToken(response.headers.authorization)
      removeTemporaryAuthToken()
      await thunkAPI.dispatch(getUserDetailsThunk())
      return response.data
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const callerVerificationVerifyOtpThunk = createAsyncThunk(
  "auth/verifyOtp/api",
  async (request: GetCallerVerifyOtpRequest, thunkAPI) => {
    try {
      const response = await callerVerificationClient.getCallerVerifyUserDetail(
        request,
      )
      // store token in local
      setAuthTokenLocal(response.headers.authorization)
      removeTemporaryAuthTokenLocal()
      return response.data
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

const initialState: AuthInitialState = {
  isLoggedIn: true,
  isLoginModalOpen: false,
  isLoading: false,
  userBasicDetails: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    markAuthStore: (
      state,
      action: PayloadAction<Partial<AuthInitialState>>,
    ) => {
      markStates(action.payload, state)
    },

    logout: (state) => {
      state.isLoggedIn = false
      state.userBasicDetails = null
      state.userRole = undefined
      cleanCookiesStorage()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOtpThunk.pending, (state) => {
      state.isLoading = true
      state.isLoggedIn = false
    })
    builder.addCase(sendOtpThunk.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(sendOtpThunk.rejected, (state) => {
      state.isLoading = false
      state.isLoggedIn = false
    })

    ///////////////// caller Verification SendOtp Thunk ////////////
    builder.addCase(callerVerificationSendOtpThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(callerVerificationSendOtpThunk.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(callerVerificationSendOtpThunk.rejected, (state) => {
      state.isLoading = false
    })

    // ////////////////// Verify OTP thunk /////////////////
    builder.addCase(verifyOtpThunk.pending, (state) => {
      state.isLoading = true
      state.isLoggedIn = false
    })
    builder.addCase(verifyOtpThunk.fulfilled, (state, action) => {
      // Store user inside cookies
      setUserCookie(action.payload.userId)
      // Store user session isValid  to redux
      state.isLoading = false
      state.isLoggedIn = true
    })
    builder.addCase(verifyOtpThunk.rejected, (state) => {
      state.isLoading = false
      state.isLoggedIn = false
    })

    // ////////////////// caller Verification Verify OTP thunk /////////////////
    builder.addCase(callerVerificationVerifyOtpThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      callerVerificationVerifyOtpThunk.fulfilled,
      (state, action) => {
        // Store user inside cookies
        setUserTokenLocal(action.payload.userId)
        // Store user session isValid  to redux
        state.isLoading = false
      },
    )
    builder.addCase(callerVerificationVerifyOtpThunk.rejected, (state) => {
      state.isLoading = false
    })

    // ////////////////// getUserDetailsThunk /////////////////
    builder.addCase(getUserDetailsThunk.fulfilled, (state, action) => {
      state.userBasicDetails = action.payload
      state.userRole = action.payload.userRole
      state.isLoggedIn = true
    })
    builder.addCase(getUserDetailsThunk.rejected, (state) => {
      cleanCookiesStorage()
      state.isLoggedIn = false
      state.userBasicDetails = null
      state.userRole = undefined
    })
  },
})

export const { logout, markAuthStore } = authSlice.actions

export const selectIsUserLoggedIn = (state: RootState) =>
  state.authReducer.isLoggedIn

export const selectIsLoginModalOpen = (state: RootState) =>
  state.authReducer.isLoginModalOpen

export const selectUserBasicDetails = (state: RootState) =>
  state.authReducer.userBasicDetails

export const selectUserId = (state: RootState) =>
  state.authReducer.userBasicDetails?.userId

export const selectUserRole = (state: RootState) => state.authReducer.userRole

export default authSlice.reducer
