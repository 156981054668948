import { Icon } from "@chakra-ui/react"

const SendEmailsIcon = () => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M20 4H8C6.89543 4 6 4.89543 6 6V15C6 16.1046 6.89543 17 8 17H20C21.1046 17 22 16.1046 22 15V6C22 4.89543 21.1046 4 20 4Z'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22 7L14.9 10.78C14.33 11.08 13.67 11.08 13.1 10.78L6 7'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2 8V19C2 20.1 2.9 21 4 21H18'
          stroke='#999A9A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  )
}

export default SendEmailsIcon
