import { AxiosInstance } from "axios"

import { bypassIdVerificationApi } from "api/bookingDashboard/bypassIdVerificationApi"
import { cancelBookingApi } from "api/bookingDashboard/cancelBookingApi"
import { cancelBookingPayoutDetailApi } from "api/bookingDashboard/cancelBookingPayoutDetailApi"
import { getCancelPolicyApi } from "api/bookingDashboard/cancelPolicyApi"
import { createInvoiceBookingApi } from "api/bookingDashboard/createInvoiceBookingApi"
import { getForceCancelBookingApi } from "api/bookingDashboard/forceCancelBookingApi"
import { getAllBookingDetailApi } from "api/bookingDashboard/getAllBookingDetailApi"
import { getAllInvoiceApi } from "api/bookingDashboard/getAllInvoiceApi"
import { getBookingGuestInfoApi } from "api/bookingDashboard/getBookingGuestInfoApi"
import { getBookingNotesApi } from "api/bookingDashboard/getBookingNotesApi"
import { getCourtDetailApi } from "api/bookingDashboard/getCourtDetailsApi"
import { getInvoiceBookingNoApi } from "api/bookingDashboard/getInvoicebyBookingNoApi"
import { getPaginatedBookingsApi } from "api/bookingDashboard/getPaginatedBookingsApi"
import { getPaymentBookingDetailApi } from "api/bookingDashboard/getPaymentBookingDetailApi"
import { getQuoteBookingApi } from "api/bookingDashboard/getQuoteBookingApi"
import { getSingleBookingDetailApi } from "api/bookingDashboard/getSingleBookingDetailApi"
import { modifyBookingApi } from "api/bookingDashboard/modifyBookingApi"
import { newInvoiceBookingApi } from "api/bookingDashboard/newInvoiceBookingApi"
import { newModifyBookingApi } from "api/bookingDashboard/newModifyBookingApi"
import { sendInvoiceApi } from "api/bookingDashboard/sendInvoiceApi"
import { updateBookingDetailApi } from "api/bookingDashboard/updateBookingDetailApi"
import { updateInvoiceBookingApi } from "api/bookingDashboard/updateInvoiceBookingApi"
import defaultAxiosInstance from "services/client"

export class BookingClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getPaginatedBookings(request: Parameters<typeof getPaginatedBookingsApi>[0]) {
    return getPaginatedBookingsApi(request, this.axiosInstance)
  }

  getSingleBookingDetail(
    request: Parameters<typeof getSingleBookingDetailApi>[0],
  ) {
    return getSingleBookingDetailApi(request, this.axiosInstance)
  }

  getAllBookingDetail(request: Parameters<typeof getAllBookingDetailApi>[0]) {
    return getAllBookingDetailApi(request, this.axiosInstance)
  }

  getPaymentBookingDetail(
    request: Parameters<typeof getPaymentBookingDetailApi>[0],
  ) {
    return getPaymentBookingDetailApi(request, this.axiosInstance)
  }

  updateBookingDetail(request: Parameters<typeof updateBookingDetailApi>[0]) {
    return updateBookingDetailApi(request, this.axiosInstance)
  }

  cancelBooking(request: Parameters<typeof cancelBookingApi>[0]) {
    return cancelBookingApi(request, this.axiosInstance)
  }

  cancelBookingPayout(
    request: Parameters<typeof cancelBookingPayoutDetailApi>[0],
  ) {
    return cancelBookingPayoutDetailApi(request, this.axiosInstance)
  }

  getCancelPolicy(request: Parameters<typeof getCancelPolicyApi>[0]) {
    return getCancelPolicyApi(request, this.axiosInstance)
  }

  getForceCancelBooking(
    request: Parameters<typeof getForceCancelBookingApi>[0],
  ) {
    return getForceCancelBookingApi(request, this.axiosInstance)
  }

  bypassIdVerification(request: Parameters<typeof bypassIdVerificationApi>[0]) {
    return bypassIdVerificationApi(request, this.axiosInstance)
  }

  getCourtDetail(request: Parameters<typeof getCourtDetailApi>[0]) {
    return getCourtDetailApi(request, this.axiosInstance)
  }

  modifyBooking(request: Parameters<typeof modifyBookingApi>[0]) {
    return modifyBookingApi(request, this.axiosInstance)
  }

  getBookingNotesApiDetails(request: Parameters<typeof getBookingNotesApi>[0]) {
    return getBookingNotesApi(request, this.axiosInstance)
  }

  getAllInvoiceApiDetails(request: Parameters<typeof getAllInvoiceApi>[0]) {
    return getAllInvoiceApi(request, this.axiosInstance)
  }

  createInvoiceBooking(request: Parameters<typeof createInvoiceBookingApi>[0]) {
    return createInvoiceBookingApi(request, this.axiosInstance)
  }

  updateInvoiceBooking(request: Parameters<typeof updateInvoiceBookingApi>[0]) {
    return updateInvoiceBookingApi(request, this.axiosInstance)
  }

  sendInvoiceApiDetails(request: Parameters<typeof sendInvoiceApi>[0]) {
    return sendInvoiceApi(request, this.axiosInstance)
  }

  getInvoiceBookingNo(request: Parameters<typeof getInvoiceBookingNoApi>[0]) {
    return getInvoiceBookingNoApi(request, this.axiosInstance)
  }

  getQuoteBooking(request: Parameters<typeof getQuoteBookingApi>[0]) {
    return getQuoteBookingApi(request, this.axiosInstance)
  }

  newModifyBooking(request: Parameters<typeof newModifyBookingApi>[0]) {
    return newModifyBookingApi(request, this.axiosInstance)
  }

  newInvoiceBooking(request: Parameters<typeof newInvoiceBookingApi>[0]) {
    return newInvoiceBookingApi(request, this.axiosInstance)
  }

  getBookingGuestInfo(request: Parameters<typeof getBookingGuestInfoApi>[0]) {
    return getBookingGuestInfoApi(request, this.axiosInstance)
  }
}

export const bookingClient = new BookingClient(defaultAxiosInstance)
