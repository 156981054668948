import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface GetQuoteType {
  pmcid: string
  unitid: string
  pmsSystem: string
  rent: number
  serviceFee: number
  hostFee: number
  taxes: number
}

interface GetQuoteBookingResponseData extends AxiosResponse {
  data: GetQuoteType
}

interface GetQuoteBookingResponse extends AxiosResponse {
  data: GetQuoteBookingResponseData
}

export interface GetQuoteRequestsType {
  bookingNumber: string | undefined
  checkinDate: undefined | string
  checkoutDate: undefined | string
  adultCount: undefined | number
  childCount: undefined | number
  pet: undefined | number
}

export interface GetQuoteBookingRequests extends Partial<AxiosRequestConfig> {
  data: GetQuoteRequestsType
}

export function getQuoteBookingApi(
  { data, ...config }: GetQuoteBookingRequests,
  axiosInstance: AxiosInstance,
): Promise<GetQuoteBookingResponse> {
  return axiosInstance({
    method: "get",
    url: `admin/booking/quote?bookingNumber=${
      data?.bookingNumber || ""
    }&checkinDate=${data?.checkinDate || ""}&checkoutDate=${
      data?.checkoutDate || ""
    }&adultCount=${data?.adultCount || 0}&childCount=${
      data?.childCount || 0
    }&pet=${data?.pet || 0}`,
    ...config,
  })
}
