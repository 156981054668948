import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface SendInvoiceType {
  apiStatus: string
  data: string
}

interface SendInvoiceResponses extends AxiosResponse {
  data: SendInvoiceType
}

export interface SendInvoiceRequests extends Partial<AxiosRequestConfig> {
  id: number | undefined
}

export function sendInvoiceApi(
  { id, ...config }: SendInvoiceRequests,
  axiosInstance: AxiosInstance,
): Promise<SendInvoiceResponses> {
  return axiosInstance({
    method: "post",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `invoice/sendInvoice?id=${id}`,
    ...config,
  })
}
