import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { CancelPolicyItem } from "pages/BookingPage/Modal/CancelPolicyModal"

export interface CancelPolicyData {
  body: string
}

interface CancelPolicyResponses extends AxiosResponse {
  data: CancelPolicyData
}

export interface CancelPolicyRequests extends Partial<AxiosRequestConfig> {
  data: CancelPolicyItem[]
}

export function getCancelPolicyApi(
  { data, ...config }: CancelPolicyRequests,
  axiosInstance: AxiosInstance,
): Promise<CancelPolicyResponses> {
  return axiosInstance({
    data,
    method: "post",
    url: `booking/cancelinsurance`,
    ...config,
  })
}
