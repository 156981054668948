import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import {
  BookingAddressObject,
  SingleBookingType,
} from "./getSingleBookingDetailApi"

interface UpdateBookingDetailResponse extends AxiosResponse {
  data: SingleBookingType
}

export interface UpdateBookingDetailRequest
  extends Partial<AxiosRequestConfig> {
  data: {
    bookingAddress?: BookingAddressObject
    cancelReason?: string
    refundResionType?: string
  }
}

export function updateBookingDetailApi(
  { data, ...config }: UpdateBookingDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<UpdateBookingDetailResponse> {
  return axiosInstance({
    data,
    method: "put",
    url: `newadmin/update-booking`,
    ...config,
  }) as Promise<UpdateBookingDetailResponse>
}
