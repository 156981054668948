import { AxiosInstance } from "axios"

import { addBcomNotesApi } from "api/bcomBookingDashboard/addBcomNotesApi"
import { getConfirmationMailApi } from "api/bcomBookingDashboard/getConfirmationMailApi"
import { getPaginatedBcomBookingsApi } from "api/bcomBookingDashboard/getPaginatedBcomBookingApi"
import { sendBcomConfirmationMailApi } from "api/bcomBookingDashboard/sendBcomConfirmationMail"
import defaultAxiosInstance from "services/client"

export class BcomBookingClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getPaginatedBcomBookings(
    request: Parameters<typeof getPaginatedBcomBookingsApi>[0],
  ) {
    return getPaginatedBcomBookingsApi(request, this.axiosInstance)
  }

  getConfirmationMail(request: Parameters<typeof getConfirmationMailApi>[0]) {
    return getConfirmationMailApi(request, this.axiosInstance)
  }

  addBcomNotes(request: Parameters<typeof addBcomNotesApi>[0]) {
    return addBcomNotesApi(request, this.axiosInstance)
  }

  sendBcomConfirmationMail(
    request: Parameters<typeof sendBcomConfirmationMailApi>[0],
  ) {
    return sendBcomConfirmationMailApi(request, this.axiosInstance)
  }
}

export const bcomBookingClient = new BcomBookingClient(defaultAxiosInstance)
