import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData, SortDirectionType } from "services/common"

export interface EventLogType {
  action: string
  detail: string
  pmcId: string
  updateDt: number
  userId: number | string
}

interface GetPaginatedEventLogResponseDataData extends Pageable {
  content: EventLogType[]
}

interface GetPaginatedEventLogResponseData extends ResponseData {
  data: GetPaginatedEventLogResponseDataData
}

interface GetPaginatedEventLogResponse extends AxiosResponse {
  data: GetPaginatedEventLogResponseData
}

export interface GetPaginatedEventLogRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    noOfRecords: number
    pageNo: number
    pmcId: string | number
    sortBy: string
    sortDirection: SortDirectionType
  }
}

export function getPaginatedEventLogApi(
  { params, ...config }: GetPaginatedEventLogRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedEventLogResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/eventlog`,
    ...config,
  })
}
