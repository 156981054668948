import { ModuleType } from "configs/permissions"
import { selectUserRole } from "stores/auth/authSlice"
import { selectRoleDetails } from "stores/ui/uiSlice"

import { useAppSelector } from "./store/hooks"

const useModulePermission = (module: ModuleType) => {
  const userRole = useAppSelector(selectUserRole)
  const roleData = useAppSelector(selectRoleDetails)
  if (userRole) {
    const hasAccess = roleData
      ?.find((user) => user.role === userRole)
      ?.rule?.find((i) => i.module === module)
    if (hasAccess) {
      return {
        hasAccessToModule: true,
        permitedActions: hasAccess.action,
      }
    }
  }
  return {
    hasAccessToModule: undefined,
    permitedActions: [],
  }
}

export default useModulePermission
