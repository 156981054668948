import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

export interface ParamsType {
  id: string
  title: string
  type: string
  propertyUrlType: string
  propertyUrl: string
  s3FileUrl: string
  altText: string
}

export interface BannerData {
  awsUrl: string
  order: number
  type: string
}

export interface GetBannerDataResponseData extends ResponseData {
  data: BannerData
}

export interface GetBannerDataResponse extends AxiosResponse {
  data: GetBannerDataResponseData
}

export interface PropertyTypeRequest extends Partial<AxiosRequestConfig> {
  params: ParamsType
}

export function addBannerDataApi(
  { params, ...config }: PropertyTypeRequest,
  axiosInstance: AxiosInstance,
): Promise<GetBannerDataResponse> {
  return axiosInstance({
    method: "post",
    data: params,
    ...config,
    url: `newadmin/hero-banner`,
  })
}
