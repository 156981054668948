import React from "react"

import * as Sentry from "@sentry/react"
import ReactDOM from "react-dom/client"
import "./index.css"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import App from "./App"

Sentry.init({
  dsn: "https://adcd905982fb4660b9b96b402e9c6c41@o4504677418991616.ingest.sentry.io/4505384692744192",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  sampleRate:
    process.env.REACT_APP_ENV == "production" ||
    process.env.REACT_APP_ENV == "staging"
      ? 1
      : 0,
  tracesSampleRate:
    process.env.REACT_APP_ENV == "production" ||
    process.env.REACT_APP_ENV == "staging"
      ? 1
      : 0,
  environment: process.env.REACT_APP_ENV || "unknown",
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
)
