import { AxiosInstance, AxiosResponse } from "axios"

import { ActionType, ModuleType, UserRoleType } from "configs/permissions"
import { ResponseData } from "services/common"

export interface GetRolesResponseData extends ResponseData {
  data: {
    role: UserRoleType
    rule: {
      module: ModuleType
      action: ActionType[]
    }[]
  }[]
}

export interface GetRolesResponse extends AxiosResponse {
  data: GetRolesResponseData
}

export function getRolesApi(
  axiosInstance: AxiosInstance,
): Promise<GetRolesResponse> {
  return axiosInstance({
    method: "get",
    url: `newadmin/role`,
  })
}
