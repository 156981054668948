import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface GetCourtType {
  hostServiceFee: number
  hostServiceFeePercent: number
  travelerServiceFee: number
  travelerServiceFeePercent: number
}

interface DataGetCourtDetailResponse extends AxiosResponse {
  data: GetCourtType | undefined
}
interface GetCourtDetailResponse extends AxiosResponse {
  data: DataGetCourtDetailResponse
}

export interface GetCourtDetailRequest extends Partial<AxiosRequestConfig> {
  data: {
    bookingNo: string | number | undefined
    originalRentAmount: number
    requiredFeeAmount: number
    rentAmount: number
    checkOutDate: string | undefined
    checkInDate: string | undefined
    hostAdditionalCharge: number
    travelerAdditionalCharge: number
  }
}

export function getCourtDetailApi(
  { data, ...config }: GetCourtDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetCourtDetailResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `booking/get-service-fee`,
    ...config,
  }) as Promise<GetCourtDetailResponse>
}
