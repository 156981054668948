import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface EmailContent {
  pmcId: string
  unitId: string
  htmlContent: string
  pmsReservationNo: string
  whimstayBookingNo: string
  emailType: string
  status: string
  createdDt: string
}

export interface GetConfirmationMailResponse extends AxiosResponse {
  data: { [key: string]: EmailContent[] }
}

export interface GetConfirmationMailRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    bookingNumber: string
  }
}

export function getConfirmationMailApi(
  { params, ...config }: GetConfirmationMailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetConfirmationMailResponse> {
  return axiosInstance({
    method: "get",
    url: `admin/confirmationMail/getConfirmationMail?bookingNumber=${params.bookingNumber}&pageNo=0`,
    ...config,
  })
}
