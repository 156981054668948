import { getCookie } from "utils/cookies"

export const setAuthTokenLocal = (value: string) =>
  localStorage.setItem(`wATAdmin`, value)
export const getAuthTokenLocal = () =>
  localStorage.getItem(`wATAdmin`) || undefined
export const removeAuthTokenLocal = () => localStorage.setItem(`wATAdmin`, "")

export const setTemporaryAuthTokenLocal = (value: string) =>
  localStorage.setItem(`wTATAdmin`, value)
export const getTemporaryAuthTokenLocal = () =>
  localStorage.getItem(`wTATAdmin`)
export const removeTemporaryAuthTokenLocal = () =>
  localStorage.setItem(`wTATAdmin`, "")

export const setReCaptchaTokenLocal = (value: string) =>
  localStorage.setItem(`rCTAdmin`, value)
export const getReCaptchaTokenLocal = () => localStorage.getItem(`rCTAdmin`)
export const removeReCaptchaTokenLocal = () =>
  localStorage.setItem(`rCTAdmin`, "")

export const setUserTokenLocal = (value: number) =>
  localStorage.setItem(`ubDAdmin`, `${value}`)
export const getUserTokenLocal = () => getCookie(`ubDAdmin`)
export const removeUserTokenLocal = () => localStorage.setItem(`ubDAdmin`, "")

export const cleanLocalsStorage = () => {
  removeUserTokenLocal()
  removeAuthTokenLocal()
  removeReCaptchaTokenLocal()
  removeTemporaryAuthTokenLocal()
}
