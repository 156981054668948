import { AxiosInstance } from "axios"

import { getUserDetailsApi } from "api/auth/getUserDetails"
import { sendOtpApi } from "api/auth/sendOtp"
import { verifySigninOtpApi } from "api/auth/verifySignInOtpApi"
import defaultAxiosInstance from "services/client"

export class AuthClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  sendOtp(request: Parameters<typeof sendOtpApi>[0]) {
    return sendOtpApi(request, this.axiosInstance)
  }

  verifyOtp(request: Parameters<typeof verifySigninOtpApi>[0]) {
    return verifySigninOtpApi(request, this.axiosInstance)
  }

  getUserDetails() {
    return getUserDetailsApi(this.axiosInstance)
  }
}

export const apiAuthClient = new AuthClient(defaultAxiosInstance)
