import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus } from "services/common"

export interface PreviewImgType {
  awsUrl: string
  type: string
}

export interface PreviewImageBannerResponse extends AxiosResponse {
  apiStatus: ApiStatus
  errorMessage: string
  data: PreviewImgType
}

export interface PreviewImageResponse extends AxiosResponse {
  data: PreviewImageBannerResponse
}

export interface PreviewTypeRequest extends Partial<AxiosRequestConfig> {
  params: { type: string }
  data?: FormData
}

export function previewImageApi(
  { params, data, ...config }: PreviewTypeRequest,

  axiosInstance: AxiosInstance,
): Promise<PreviewImageResponse> {
  return axiosInstance({
    method: "post",
    url: `newadmin/hero-banner/preview?type=${params.type}`,
    ...config,
    data,
  })
}
