import { Icon } from "@chakra-ui/react"

const HostPayoutDashboardIcon = () => {
  return (
    <Icon
      xmlns='http://www.w3.org/2000/svg'
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M3.85019 8.61998C3.70423 7.9625 3.72665 7.27882 3.91535 6.63231C4.10405 5.98581 4.45294 5.39742 4.92966 4.9217C5.40638 4.44597 5.9955 4.09832 6.6424 3.91097C7.2893 3.72362 7.97303 3.70264 8.63019 3.84998C8.9919 3.28428 9.4902 2.81873 10.0791 2.49626C10.6681 2.17379 11.3287 2.00476 12.0002 2.00476C12.6716 2.00476 13.3323 2.17379 13.9212 2.49626C14.5102 2.81873 15.0085 3.28428 15.3702 3.84998C16.0284 3.702 16.7133 3.72288 17.3612 3.91069C18.0091 4.09849 18.599 4.44712 19.076 4.92413C19.5531 5.40114 19.9017 5.99105 20.0895 6.63898C20.2773 7.28691 20.2982 7.97181 20.1502 8.62998C20.7159 8.99168 21.1814 9.48998 21.5039 10.0789C21.8264 10.6679 21.9954 11.3285 21.9954 12C21.9954 12.6714 21.8264 13.3321 21.5039 13.921C21.1814 14.51 20.7159 15.0083 20.1502 15.37C20.2975 16.0271 20.2765 16.7109 20.0892 17.3578C19.9018 18.0047 19.5542 18.5938 19.0785 19.0705C18.6027 19.5472 18.0144 19.8961 17.3679 20.0848C16.7213 20.2735 16.0377 20.2959 15.3802 20.15C15.019 20.7178 14.5203 21.1854 13.9303 21.5093C13.3404 21.8332 12.6782 22.003 12.0052 22.003C11.3322 22.003 10.67 21.8332 10.0801 21.5093C9.49011 21.1854 8.99143 20.7178 8.63019 20.15C7.97303 20.2973 7.2893 20.2763 6.6424 20.089C5.9955 19.9016 5.40638 19.554 4.92966 19.0783C4.45294 18.6025 4.10405 18.0141 3.91535 17.3676C3.72665 16.7211 3.70423 16.0374 3.85019 15.38C3.28015 15.0192 2.81061 14.5201 2.48524 13.9292C2.15988 13.3382 1.98926 12.6746 1.98926 12C1.98926 11.3254 2.15988 10.6617 2.48524 10.0708C2.81061 9.4798 3.28015 8.98073 3.85019 8.61998Z'
        stroke='#999A9A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 8H10C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10C8 10.5304 8.21071 11.0391 8.58579 11.4142C8.96086 11.7893 9.46957 12 10 12H14C14.5304 12 15.0391 12.2107 15.4142 12.5858C15.7893 12.9609 16 13.4696 16 14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H8'
        stroke='#999A9A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 18V6'
        stroke='#999A9A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  )
}

export default HostPayoutDashboardIcon
