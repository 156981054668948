import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface SearchParameters {
  numberOfGuest: string
  distance: string
  adultCount: string
  whimstayPlus: string
  placeId: string
  noOfDays: string
  elevator: string
  ratingEqualAndAbove: string
  limit: string
  petAllowed: string
  childAllowed: string
  sortBy: string
  lat: string
  childrenCount: string
  minBathRoom: string
  lng: string
  smokeAllowed: string
  maxprice: string
  zoom: string
  placeType: string
  placeDesc: string
  minPrice: string
  name: string
  bounds: string
  isSearchingInMap: string
  page: string
  minBedRoom: string
  eventAllowed: string
}

export interface BannerListType {
  id: string
  awsUrl: string
  altText: string
  order: number
  type: string
  title: string
  propertyUrl: string
  propertyUrlType: string
  city: string
  state: string
  country: string
  isClickable: boolean
  isDelete: boolean
  searchParameters?: SearchParameters
}

export interface BannerListTypeResponse {
  data: BannerListType[]
}

export interface BannerListTypeResponses extends AxiosResponse {
  data: BannerListTypeResponse
}

export interface BannerListTypeRequests extends Partial<AxiosRequestConfig> {
  params: string
}

export function getBannerListApi(
  { params, ...config }: BannerListTypeRequests,
  axiosInstance: AxiosInstance,
): Promise<BannerListTypeResponses> {
  return axiosInstance({
    method: "get",
    url: `home/hero-banner/all?type=${params}`,
    ...config,
  })
}
