import React, { useState } from "react"

import { Box, Button, Input, Select, Text } from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"

import { codesSelect } from "services/common"

const LoginForm = ({
  onSubmit,
}: {
  onSubmit: (payload: { code: string; number: string }) => void
}) => {
  const [errorMessage, setErrorMessage] = useState("")
  function validate(phone: string) {
    if (isNaN(Number(phone))) {
      setErrorMessage("please enter digits only")
      return false
    } else if (phone.length < 10) {
      setErrorMessage("invalid mobile number")
      return false
    } else if (phone.length > 12) {
      setErrorMessage("invalid mobile number")
      return false
    } else {
      setErrorMessage("")
      return true
    }
  }

  return (
    <>
      <Text fontFamily={"'Lubalin Graph Std Demi', sans-serif"}>Sign In</Text>
      <Text my={2} fontSize={"14px"}>
        Sign in using your mobile number.
      </Text>
      <Formik
        initialValues={{ number: "", code: "+1" }}
        validateOnChange={false}
        validationSchema={Yup.object({
          code: Yup.string().required("Required"),
          number: Yup.string()
            .max(12, "Must be 12 digits or less")
            .min(10, "Must be 10 digits or more")
            .matches(/^\d{10,12}$/, "Must be only digits")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (validate(values.number)) {
            onSubmit({
              code: values.code,
              number: values.number,
            })
            setSubmitting(false)
          }
        }}
      >
        {({ errors }) => (
          <Form>
            <Box display='flex'>
              <Box w='20%' mr='20px'>
                <Field
                  as={Select}
                  name={"code"}
                  className='form-select'
                  py='0px'
                  color='textColor'
                  fontSize='sm'
                  fontWeight='medium'
                  borderRadius='20px'
                  border='1px solid #EEE'
                  defaultValue={codesSelect?.[0]?.id}
                >
                  {codesSelect.map((dat, index) => (
                    <option key={index} value={dat.id}>
                      {dat.id + "  " + dat.name}
                    </option>
                  ))}
                </Field>
              </Box>
              <Box w='80%'>
                <Box
                  display={"flex"}
                  flexDirection='column'
                  alignItems='center'
                >
                  <Field
                    as={Input}
                    display='inline-block'
                    w='100%'
                    name='number'
                    type='number'
                    maxLength={12}
                    placeholder='Enter Your Phone Number'
                    isRequired={true}
                    max={999999999999}
                    borderRadius='20px'
                    border='1px solid #EEE'
                  />
                </Box>
                <Text color='red' fontSize='14px' mt='4px'>
                  {errorMessage || errors.code || errors.number}
                </Text>
              </Box>
            </Box>
            <Button type='submit' w='full' size='lg' mt='6' variant='primary'>
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
export default LoginForm
