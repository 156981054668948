import { AxiosInstance, AxiosResponse } from "axios"

import { UserRoleType } from "configs/permissions"

interface GetUserDetailResponseData {
  admin: boolean
  city: string
  country: string
  createdDt: number
  dialerCode: string
  dobDt: string
  emailId: string
  emailVerified: boolean
  firstName: string
  host: boolean
  lastName: string
  location: string
  mobileVerified: boolean
  mobileno1: string
  profileImageFullPath: string
  profileImg: string
  registeringYear: number
  state: string
  traveler: boolean
  userId: number
  userInfoDTO: {
    location: string
    nickName: string
    numberOfFamilyMembers: number
    pets: number
    pronouns: string
    userFavouritePropertyIds: string[]
  }
  userRole: UserRoleType
  zipcode: null | string
}
export interface GetUserDetailResponse extends AxiosResponse {
  data: GetUserDetailResponseData
}

export function getUserDetailsApi(
  axiosInstance: AxiosInstance,
): Promise<GetUserDetailResponse> {
  return axiosInstance({
    method: "get",
    url: `user/details`,
  })
}
