import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

interface SaveAdminManageRoleDetailsResponseData extends ResponseData {
  data: {
    role: string
    rule: { module: string; action: string[] }[]
  }
}

interface SaveAdminManageRoleDetailsResponse extends AxiosResponse {
  data: SaveAdminManageRoleDetailsResponseData
}

export interface SaveAdminManageRoleDetailsRequest
  extends Partial<AxiosRequestConfig> {
  data:
    | {
        role: string
        rule: { module: string; action: string[] }[]
      }
    | object
}

export function saveAdminManageRoleApi(
  { data, ...config }: SaveAdminManageRoleDetailsRequest,
  axiosInstance: AxiosInstance,
): Promise<SaveAdminManageRoleDetailsResponse> {
  return axiosInstance({
    method: "post",
    data,
    url: `newadmin/save-admin-manage-role`,
    ...config,
  })
}
