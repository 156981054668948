import { useCallback, useEffect } from "react"

import { captureException } from "@sentry/react"

import { getProjectWindow, injectScript, removeScript } from "utils/html-dom"

const ERROR_SCRIPT_NOT_AVAILABLE = "Google recaptcha is not available"
const ERROR_NOT_A_WEB_BROWSER = "Running outside a web browser"

const RECAPTCHA_SCRIPT = "https://www.recaptcha.net/recaptcha/api.js"
const SCRIPT_ID = "google-recaptcha-v3"

const RECAPTCHA_KEY = "6Lf3MLgfAAAAABsi9NgxTbgYpxQRUT3rdxCZWtgD"

interface IReCaptchaHookReturn {
  getToken: (action?: string) => Promise<string>
}

type TReCaptchaHook = () => IReCaptchaHookReturn

const siteKey = RECAPTCHA_KEY

export const useReCaptcha: TReCaptchaHook = () => {
  const getToken = useCallback(async (_action?: string): Promise<string> => {
    const window = getProjectWindow()
    if (!window) {
      throw new Error(ERROR_NOT_A_WEB_BROWSER)
    }

    const { grecaptcha } = window
    if (!grecaptcha) {
      throw new Error(ERROR_SCRIPT_NOT_AVAILABLE)
    }

    return new Promise((resolve) => {
      if (_action) {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(siteKey, { action: _action })
            .then((token) => resolve(token))
            .catch((e) => {
              console.error(e)
              captureException(
                new Error(`Error grecaptcha.execute ${JSON.stringify(e)}`),
              )
            })
        })
      }
    })
  }, [])

  useEffect(() => {
    if (!siteKey) {
      return
    }
    const window = getProjectWindow()
    if (window) {
      injectScript(SCRIPT_ID, `${RECAPTCHA_SCRIPT}?render=${siteKey}`)
    }
    return () => {
      removeScript(SCRIPT_ID)
    }
  }, [])

  return { getToken }
}
