import { AxiosInstance } from "axios"

import { addBannerDataApi } from "api/heroBanner/addBannerApi"
import { changeOrderListApi } from "api/heroBanner/changeOrderListApi"
import { getBannerListApi } from "api/heroBanner/getBannerListApi"
import { previewImageApi } from "api/heroBanner/previewImageApi"
import defaultAxiosInstance from "services/client"

export class HeroBannerClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getBannerLists(request: Parameters<typeof getBannerListApi>[0]) {
    return getBannerListApi(request, this.axiosInstance)
  }

  addBannerData(request: Parameters<typeof addBannerDataApi>[0]) {
    return addBannerDataApi(request, this.axiosInstance)
  }

  changeOrderList(request: Parameters<typeof changeOrderListApi>[0]) {
    return changeOrderListApi(request, this.axiosInstance)
  }

  previewImageBanner(request: Parameters<typeof previewImageApi>[0]) {
    return previewImageApi(request, this.axiosInstance)
  }
}

export const heroBannerClient = new HeroBannerClient(defaultAxiosInstance)
