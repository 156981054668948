import { AxiosInstance } from "axios"

import { getRolesApi } from "api/permissions/getRole"
import { saveAdminManageRoleApi } from "api/permissions/saveAdminManageRole"
import defaultAxiosInstance from "services/client"

export class PermissionsClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getRoleApi() {
    return getRolesApi(this.axiosInstance)
  }

  saveAdminManageRole(request: Parameters<typeof saveAdminManageRoleApi>[0]) {
    return saveAdminManageRoleApi(request, this.axiosInstance)
  }
}

export const apiPermissionsClient = new PermissionsClient(defaultAxiosInstance)
