const colors = {
  textColor: "#000000", // Black color for
  grayText: "#999A9A",
  offWhite: "#FFFFFF",
  glacierColor: "#B1E4E3",
  brandGray: "#EEEEEE",

  tag: {
    LIVE: "rgba(26,185,34,0.9)",
    ARCHIVED: "rgba(152,145,145,0.9)",
    "IN-PROCESS": "rgba(255,208,0,0.9)",
    TEST: "rgba(14,146,207,.9)",
    "ON-HOLD": "rgba(211,101,238,0.9)",
  },

  primary: {
    50: "#fffadc",
    100: "#fcefb1",
    200: "#f8e484",
    300: "#f5d954",
    400: "#f2ce26",
    500: "#F1C911",
    600: "#a98c05",
    700: "#786401",
    800: "#483c00",
    900: "#1a1400",
  },
  black: {
    50: "#f2f2f2",
    75: "#EEEEEE",
    100: "#d9d9d9",
    200: "#bfbfbf",
    300: "#a6a6a6",
    400: "#8c8c8c",
    500: "#595959",
    600: "#404040",
    700: "#262626",
    800: "#0d0d0d",
    900: "#000000",
    1000: "#222222",
  },
  secondary: {
    25: "#CFE2E6",
    50: "#B6D4D9",
    75: "#9EC5CD",
    100: "#6EA9B3",
    200: "#569AA7",
    300: "#3D8C9A",
    400: "#257D8E",
    500: "#0D6F81",
    600: "#0A5967",
    700: "#08434D",
    800: "#052C34",
    900: "#03161A",
    1000: "#006f80",
  },
}

export default colors
