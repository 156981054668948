import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface BookingFeesType {
  applied: null | boolean
  currency: null | string
  description: string
  feeId: null | number
  id: number
  optional: null | string
  value: number
}
export interface BookingSplitPromoCode {
  promoCode: string
  value: null | number
}

export interface BookingAddressObject {
  address: string
  addressId: number
  city: string
  country: string
  emailId: string
  firstName: string
  lastName: string
  mobileNumber: string
  dialerCode: string
  nickName: string | null
  pronouns: string | null
  state: string
  userId: number
  zipCode: number | string | undefined
}

export interface BookingBillingAddressObject {
  address: string
  bookingId: number
  bookingNumber: string
  city: string
  country: string
  firstName: string
  id: number
  lastName: string
  mobileNo: string
  userId: number
  state: string
  travelerBookingEmail: string
  travelerId: number
  zipCode: number | string | undefined
}

export interface PaymentChargeLogArray {
  bookingId: number
  bookingNo: string
  chargeAmount: number
  chargeCaptureAmount: number
  currency: string
  groupId: string | null
  isActive: boolean
  isCapture: boolean
  isIgnore?: boolean
  isChargeSuccessful: boolean
  isSplitBookingCharge: boolean
  logCharacter: null | string
  modifiedId: null | string | number
  paymentType: string
  refundAmount: number
  status: string
  stripeChargeId: null | string
  stripeCustomerId: string
  stripeIntentId: string
  travellerId: number
  wmChargeId: number
  paymentDate: string | null
}
export interface PaymentTransferLogArray {
  length?: number
  bookingNo: string
  currency: string
  isActive: boolean
  isTransactionReverse: boolean
  isTransactionSucessfull: boolean
  isCancelStatus?: boolean
  logCharacter: null | string
  modifiedId: null | string | number
  note: null | string
  paymentDate: null | string
  pmcId: string
  reason: null | string
  status: string
  stripeTranferId: null | string
  transactionId: number
  transferAmount: number
  transferReversedAmount: number
  transferSendDate: string
  transferType: string
}
export interface BookingSplitUser {
  addressId: number
  bookingId: number
  bookingNumber: string
  bookingUserId: number
  createdAt: number
  dialerCode: string
  firstName: string
  insuranceAmount: number
  isDeducted: number
  isOwner: number
  mailSent: number
  mobileNumber: string
  promoCode: null | BookingSplitPromoCode
  promoCodeAppliedAmount: number
  refundAmount: null | number
  refundProtect: boolean
  isInsuranceCancel: boolean
  status: string
  totalPaidByTraveler?: number
  transferType: string
  travelerBookingEmail: string
  travelerChargeAmount: number
  travelerPaymentAmount: number
  userId: number
  whimstayInsuranceAmount: number
}
export interface BookingTaxes {
  currency: null | string
  description: string
  id: number
  percent: number
  taxId: null | number
  type: null | number
  value: number
}

export interface SingleBookingType {
  apiStatus: string
  errorMessage: string
  bookingModified: boolean
  paymentMode: string
  insuranceType: string
  contactEmail: string
  companyName: string
  childNumber: number
  discountPercentApplied: number | null | string
  promoCode: null | BookingSplitPromoCode
  message: string
  note: string
  adultNumber: number
  bookingAmount: number
  bookingDate: number
  affirmFee: number
  bookingId: number
  bookingNumber: string
  refundPaidDate: null | string
  cancellationDate: null | string
  payoutReversalDate: null | string
  cancelReason: null | string
  checkInDate: string
  checkOutDate: string
  currency: string
  fees: BookingFeesType[] | null
  hostEmail: string
  hostMobile: string
  hostName?: string
  hostTransferAmount: number
  id: string
  insuranceAmount: number
  isSplited: number
  nightCount: string
  optionalFeeAmount: number
  partialRefund: null
  petNumber: number
  pmcId: string
  pmsReservationNumber: string
  pmsUnitId: string
  promoCodeAppliedAmount: number
  promoCodeName: null | string
  propertyFullAddress: string
  propertyName: string
  refundAmount?: null | undefined | number
  refundResionType: null | string
  rentAmount: number
  requiredFeeAmount: number
  splitUserVO: BookingSplitUser[]
  state: string
  status: string
  taxAmount: number
  taxes: BookingTaxes[] | null
  travelerEmail: string
  travelerId: number
  travelerMobile: string
  travelerName: string
  refundReason: string
  payoutReason: string
  travelerPaymentAmount: number
  userId?: number
  whimstayTravelerServiceFeeAmount: number
  whimstayHostServiceFeeAmount: number
  isInsuranceCancel: boolean
  paymentChargeLog: PaymentChargeLogArray[]
  paymentTransferLog: PaymentTransferLogArray[]
  bookingAddress: BookingAddressObject
  bookingBillingAddress: BookingBillingAddressObject
  pmsSystem: string
  cancellationFee?: number | null
}

interface GetSingleBookingDetailResponse extends AxiosResponse {
  data: SingleBookingType
}

export interface GetSingleBookingDetailRequest
  extends Partial<AxiosRequestConfig> {
  bookingNo: string
}

export function getSingleBookingDetailApi(
  { bookingNo, ...config }: GetSingleBookingDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetSingleBookingDetailResponse> {
  return axiosInstance({
    method: "get",
    url: `newadmin/getbookings/${bookingNo}`,
    ...config,
  })
}
