import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus, ResponseData } from "services/common"

export interface PropertyDataType {
  propertyId: string
  id: string
  typeId: number
}

interface AddPropertyDataResponseData extends ResponseData {
  data: PropertyDataType
  apiStatus: ApiStatus
  errorMessage: string
}

interface AddPropertyDataResponse extends AxiosResponse {
  data: AddPropertyDataResponseData
}

export interface AddPropertyDataRequest extends Partial<AxiosRequestConfig> {
  params: {
    propertyId: string | undefined
    typeId: number
  }
}

export function addPropertyDataApi(
  { params, ...config }: AddPropertyDataRequest,
  axiosInstance: AxiosInstance,
): Promise<AddPropertyDataResponse> {
  return axiosInstance({
    data: params,
    method: "post",
    url: `newadmin/property-management/configure`,
    ...config,
  })
}
