import { CloseIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react"

const CustomToast = ({
  message,
  description,
}: {
  description?: string
  message: string
}) => {
  const toast = useToast()
  const handleClose = () => {
    toast.closeAll()
  }
  return (
    <Box bg='white' w={"full"} shadow={"xl"}>
      <Box
        bg={"secondary.1000"}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        p={2}
      >
        <IconButton
          aria-label='close toast'
          variant='icon'
          onClick={handleClose}
        >
          <CloseIcon color='#fff' />
        </IconButton>
      </Box>
      <Box p={4} my={"10px"}>
        <Text fontWeight={"500"}>{message}</Text>
        {description && (
          <Text mt='1' size='sm'>
            {description}
          </Text>
        )}
      </Box>
      <Divider />
      <Box display={"flex"} alignItems={"center"} justifyContent={"end"} p={3}>
        <Button variant={"ghost"} onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  )
}

export default CustomToast
